.card {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: 0 1rem 2rem;

  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  overflow: hidden;
}
