.inputRadio {
  accent-color: #eb7129;
}

.textInput,
.selectInput,
.textAreaInput {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
  border-radius: 0;
  accent-color: #eb7129;
}

.selectInput {
  font-size: 1rem;
  font-weight: bold;
}

.textAreaInput {
  resize: none;
}

.textAreaInput::placeholder,
.textInput::placeholder {
  color: #20212380;
}

/* REMOVE ESTILOS DE INPUTS DO TIPO NUMBER */

/* Chrome, Safari, Edge, Opera */
.textInput ::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.textInput {
  -moz-appearance: textfield;
}

.formTextLabel {
  margin-bottom: 8px;
  font-size: 1.05rem;
  font-weight: 600;
}

.btn-disabled:disabled {
  filter: brightness(0.9);
  cursor: not-allowed;
}
