.text,
.listItems > li {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.4;
  color: #202123;
}

.text {
  margin-bottom: 0 !important;
}

.bold {
  color: #eb7129;
  font-size: 1.125rem;
}

.listItems {
  padding: 0 1rem;
  margin-top: 1rem;
}

.listItems > li {
  list-style-type: decimal;
}
