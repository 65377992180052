.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);

  border-radius: 30px 30px 30px 30px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transition: transform 180ms ease-in-out;
}

.cardContainer:hover {
  transform: translateY(-10px);
}

.cardInner {
}

.cardHeader {
  padding: 1.5rem;
}

.title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  color: #202123;
  text-transform: uppercase;
}

.cardBody {
  flex: 1 1 auto;
  min-height: 1px;
}

.cardBody > img {
  border-radius: 0 0 30px 30px !important;
}
