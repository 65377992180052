.cardTestimonials {
  display: flex;
  align-items: flex-start;
}

.cardContainer {
  margin: 0 80px 20px 0;

  display: flex;
  flex-direction: column;

  white-space: pre-wrap;
}

.cardContainer > div > p {
  max-width: 400px;
  margin-top: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #202123;
}

.profile {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.profile > img {
  width: 70px;
  height: 70px;
  border-radius: 50%;

  flex-shrink: 0;
}

.defaultImage {
  width: 70px;
  height: 70px;
  border-radius: 50%;

  flex-shrink: 0;
}

.profile > div {
  width: 70px;
  height: 70px;
  border-radius: 50%;

  flex-shrink: 0;
}

.inforsProfile {
  margin-left: 12px;

  display: flex;
  flex-direction: column;
}

.inforsProfile span:first-child {
  font-weight: 600;
  color: #202123;
}

.inforsProfile span:last-child {
  color: #eb7129;
  margin-top: -4px;
}

@media only screen and (max-width: 768px) {
  .cardContainer {
    margin: 0 0 20px 0;
    width: 100%;
  }

  .cardTestimonials > img {
    display: none;
  }
}
