.productsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.sectionHeader {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.sectionHeader h4 {
  font-weight: 600;
  font-size: 1.5rem;

  margin-bottom: 2rem;
  color: #20212380;
}

.headerText {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #20212380;

  margin-bottom: 2rem;
}

.productsContainer button {
  margin: 2rem 0 2rem 0;

  display: flex;
  align-self: center;
  justify-self: center;

  filter: grayscale(100%) !important;
}
