:root {
  accent-color: #ea7129;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  transform: none !important;
}

.ff-montserrat {
  font-family: none;
  font-family: 'Montserrat', sans-serif;
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.theme-color-primary {
  color: #202123;
}

.theme-bg-primary {
  background: #202123;
}

.input-content {
  background-color: #f6f5f5;
  border: 1px solid #aeadad;
  border-radius: 0;
}

/* Animations */
.animate-fade-in {
  animation: fadein 2.5s;
  -moz-animation: fadein 2.5s;
  /* Firefox */
  -webkit-animation: fadein 2.5s;
  /* Safari and Chrome */
  -o-animation: fadein 2.5s;
  /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-zoom-out:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.3s ease;
}

.animate-zoom-out-2x:hover {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  transition: all 0.3s ease;
}

.animate-zoom-in:hover {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  transition: all 0.3s ease;
}

.rotate:hover {
  -webkit-transform: rotateZ(-30deg);
  -ms-transform: rotateZ(-30deg);
  transform: rotateZ(-30deg);
  transition: all 0.3s ease;
}

.animate-fading {
  animation: fading 1.5s infinite;
}

@keyframes fading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-opacity {
  animation: opac 1.5s;
}

@keyframes opac {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-top {
  position: relative;
  animation: animatetop 0.9s;
}

@keyframes animatetop {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.animate-left {
  position: relative;
  animation: animateleft 0.9s;
}

@keyframes animateleft {
  from {
    left: -100px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.animate-right {
  position: relative;
  animation: animateright 0.9s;
}

@keyframes animateright {
  from {
    right: -200px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.animate-bottom {
  position: relative;
  animation: animatebottom 0.9s;
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.react-dropdown-select {
  margin: 0;
  padding: 10px 16px !important;
  font-size: 16px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.49);
  border: 1px solid #aeadad !important;
  border-radius: 0 !important;
}

.react-dropdown-select-option {
  background: transparent !important;
  color: #eb7129;
  border: 1px solid #eb7129 !important;
  border-radius: 0px !important;
}

.editor-class {
  background: #ffffff;
}

.react-dropdown-select-option-remove,
.react-dropdown-select-option-label {
  color: #eb7129;
}

.react-dropdown-select-option-label {
  font-size: 12px;
}

.react-dropdown-select-item-selected {
  background: #cccccc !important;
}

.react-dropdown-select-no-data {
  color: #eb7129 !important;
}

.slick-dots {
  margin-top: 10px;
  margin-bottom: 0 !important;

  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-dots > li {
  margin-bottom: 0 !important;
  line-height: 1.2;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .nk-menu-link {
    margin: 0;
  }
}

.ql-editor {
  min-height: 11rem;
  font-family: 'Montserrat', sans-serif;
}

.ql-editor h1 {
  font-weight: bold;
}

.ql-toolbar.ql-snow {
  background: #ffffff;
  border: 1px solid #aeadad !important;
}

.ql-container.ql-snow {
  border-right: 1px solid #aeadad !important;
  border-bottom: 1px solid #aeadad !important;
  border-left: 1px solid #aeadad !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  font-size: 14px !important;
  color: #20212380 !important;
}

.ql-editor iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  opacity: 0.5;
  color: #202123;
  cursor: not-allowed;
  background: none;
}

.ql-tooltip.ql-editing {
  top: 0 !important;
  left: 420px !important;
}

.slick-vertical .slick-track {
  height: auto !important;
}

.modalLarge {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

@media only screen and (min-width: 992px) {
  .modalLarge .modal-lg {
    max-width: 820px !important;
  }

  .react-pdf__Document {
    width: 100%;
    min-height: 60vh;
  }
}
@media only screen and (min-width: 768px) {
  .react-pdf__Document {
    min-height: 100vh;
  }

  .react-pdf__Page__svg {
    width: 100% !important;
    height: 100% !important;
  }

  .react-pdf__Page__svg {
    display: flex !important;
    justify-content: center !important;
  }
}
.modalLarge {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

@media only screen and (min-width: 992px) {
  .modalLarge .modal-lg {
    max-width: 820px !important;
  }
}
.ql-align-center {
  text-align: center !important;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-justify {
  text-align: justify !important;
}

.nk-menu-link {
  padding: 0.625rem 1.5rem !important;
}

.rdp-cell button {
  padding: 8px 13px;
  margin: 0;
}
.rdp-cell button:hover {
  background-color: #ee692499;
  border-radius: 100%;
  padding: 8px 13px;
}

.selected {
  background-color: #ee692499;
  font-weight: 600;
  color: #f5f5f5;
  padding: 8px 13px;
  margin: 0px;
  border-spacing: 0;
}
.range_start {
  background-color: #ee692499;
  border-radius: 50%;
}
.range_middle {
  background-color: #ee692499;
  border-radius: 50%;
}

.range_end {
  background-color: #ee692499;
  border-radius: 50%;
}

table.rdp-table {
  border-spacing: 2;
  border-collapse: collapse;
  padding: 15px;
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  text-align: 'center';
}

.chakra-collapse-hover {
  display: none;
}

@media only screen and (min-width: 768px) {
  .dayPicker {
    right: -7rem;
  }
}

select#country.chakra-select.css-1phqwe {
  -webkit-padding-start: 3.5rem;
  padding-inline-start: 3.5rem;
}
