.container {
  position: relative;
  margin-left: 0.5rem;
  flex-shrink: 0;
  cursor: pointer;
}

.container:hover .content {
  top: 2rem;
  opacity: 1;
  visibility: visible;
}

.content {
  position: absolute;
  top: 0;
  left: 50%;

  width: 20rem;
  padding: 0.8rem;
  text-align: left;

  background: #202123;
  color: #ffffff;
  border-radius: 0.2rem;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.16);

  opacity: 0;
  visibility: hidden;
  transition: opacity 100ms, transform 100ms, top 100ms, 380ms ease-in-out;

  transform: translateX(-50%);
  z-index: 12;
}

.arrow {
  position: relative;
  top: -1.2rem;
  left: 50%;
  width: 0;
  height: 0;

  border-bottom: 8px solid #202123;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;

  transform: translateX(-50%);
  z-index: 12;
}

.content p {
  font-size: 0.75rem;
  font-weight: normal;
}

.content p > a {
  text-decoration: underline;
  color: #ffffff;
}
