.accordion-module {
  margin-bottom: 1.25rem !important;
  border: 0 !important;
  border-bottom: 1px solid #dbdfea !important;
}

.card-header-module {
  height: 4rem !important;
  display: flex;
  align-items: center;
}

.card-body-module {
  border-left: 1px solid #dbdfea;
  border-right: 1px solid #dbdfea;
}

.lessons-between {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number-lessons {
  display: flex;
  align-items: center;
}

.number-lessons span {
  margin-left: 0.4rem;
}

.button-collapse {
  width: 100%;
  padding: 0;
  text-align: inherit;
  border: none;
  background: none;
}

.button-collapse:focus {
  outline: none;
}

.number-lessons {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.1;
}

.container-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .container-title > span {
    width: 75vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.container-title svg {
  margin-right: 20px;
}

.rotate-icon {
  transform: rotate(90deg);
  transition: transform 280ms ease-in-out;
}
