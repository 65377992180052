.sectionContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  padding: 0.5rem 2rem;
  margin-bottom: 2.5rem;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  position: relative;
}

.sectionHeader h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

.sectionContainer button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem 1rem;

  font-weight: 600;
  text-align: center;
  color: #f6f8fe;
  line-height: 1.5;
  letter-spacing: 0.02em;
  font-size: 1.125rem;

  vertical-align: middle;
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: #eb7129;

  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.sectionContainer button:hover {
  filter: brightness(0.96);
}

.sectionContainer button:disabled {
  cursor: not-allowed;
  filter: brightness(0.8);
}

.sectionContainer button svg {
  margin-right: 0.5rem;
}
