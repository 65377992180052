.container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: #202123;
  /* margin-top: 2rem; */
}

.container li {
  margin: 0 1rem;
}

.container li:first-child {
  margin-right: -0.5rem;
}

.container li:last-child {
  margin-left: -0.5rem;
}

.container a {
  cursor: pointer;
}

.container li {
  font-size: 0.875rem;
  font-weight: 500;
}

.activePagination {
  color: var(--chakra-colors-primary-500);
}

.activePagination a {
  font-weight: bold;
}
