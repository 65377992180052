.container {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;
}

.header h4 {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.videoWrapper {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  margin: 1rem;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .videoWrapper {
    margin: 0 1rem;
  }
}
