.div_image {
  display: flex;
  justify-content: center;
}

.image {
  width: 350px;
  height: 350px;
}

.div_text {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.text_coming_soon {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;

  color: #000000;
}

.div_btn {
  display: flex;
  justify-content: center;
}

.btn {
  background-color: #eb7129;
  color: white;
  align-items: center;
}

.eventImg {
  width: 100%;
  height: 350px;
  background-image: url("../../assets/images/backgrounds/evento-17-19-22-desktop.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 768px) {
  .eventImg {
    width: 100%;
    height: 500px;
    background-image: url("../../assets/images/backgrounds/evento-17-19-22-mobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}