.sale-content-title {
  font-size: 1.125rem;
  color: #202123;
}

.formatted-contentType {
  font-size: 1rem;
  color: #202123;
}

@media (min-width: 320px) and (max-width: 768px) {
  .sale-content-title,
  .formatted-contentType {
    font-size: 0.875rem;
  }
}
