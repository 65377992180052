.aboutSection {
  padding: 0rem 3rem;
  overflow: hidden;
}

.aboutSection > img {
  max-width: 100%;
  max-height: 700px;
}

.aboutSection,
.aboutContainer {
  display: flex;
}

.aboutContent {
  margin-left: 24px;
  text-align: start;

  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
}

.aboutContent > a {
  margin: 0.25rem 0;
  color: #202123;
}

.aboutContent > a > h4 {
  margin-bottom: 40px;
  text-transform: uppercase;

  line-height: 1.4;
  color: #202123;
}

.aboutContent > p {
  font-size: 18px;
  line-height: 1.4;
  color: #202123;
}

@media only screen and (max-width: 768px) {
  .aboutSection {
    flex-direction: column;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  .aboutContainer {
    display: flex;
    flex-direction: column;
  }

  .aboutContent {
    margin: 0.25rem 0 0.25rem 0;
    padding: 0;
  }
}
