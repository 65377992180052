.div_imagem {
  display: flex;
  justify-content: center;
}

.imagem {
  width: 350px;
  height: 350px;
}

.div_text {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.text_not_access {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;

  color: #000000;
}

.div_btn {
  display: flex;
  justify-content: center;
}

.btn {
  background-color: #eb7129;
  color: white;
  align-items: center;
}
