.fw-700 {
  font-weight: 700;
}

.wrapper__post {
  margin-top: 6.25rem;
  padding: 0 5.5rem;
}

.post__container {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post__content {
  position: relative;
  width: 100%;
}

.post__content .post__link {
  margin: 0;
  font-size: 16px;
  line-height: 1.8;
  text-decoration: none;
}

.post__content .post__subtitle {
  margin: 0 0 16px 0;
  font-size: 24px;
  font-weight: 500;
  color: #00000080;
}

.post__content h1,
.post__content h3 {
  line-height: 1.2;
}

.post__content h1 {
  font-size: 32px;
  text-transform: uppercase;
}

.post__content h3 {
  margin: 0;
  font-size: 24px;
}

.post__content h2 {
  margin: 0;
  max-width: 830px;
  font-size: 32px;
  line-height: 1.4;
}

.post__content h1,
.post__content h2,
.post__content h3 {
  font-weight: 700;
}

.post__content p {
  margin: 0;
  line-height: 1.8;
}

.post__content ul li,
.post__content ol li {
  line-height: 1.4;
  color: #000000;
}

.post__content p,
.post__content ul li,
.post__content ol li {
  font-size: 18px;
}

.post__content img {
  margin: 0 0 16px 0;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.post__content pre {
  margin: 0;
  padding: 16px;
  font-size: 16px;
  border-radius: 8px;
  background: #202123;
  color: #f5f5f5;
}

.post__content ul,
.post__content ol {
  margin: 0 0 16px 32px;
}

.post__content ul {
  list-style: disc;
}

.post__content ol {
  list-style: decimal;
}

.post__content ul li,
.post__content ol li {
  margin: 0 0 8px 0;
}

.post__content a {
  margin: 0 0 16px 0;

  font-size: 18px;
  line-height: 1.4;

  color: #3aa8ff;
  text-decoration: underline;
  transition: all 180ms ease-in-out;
}

.post__content a:hover {
  filter: brightness(0.8);
}

.post__content blockquote {
  padding: 0 30px;
  font-size: 16px;
  color: #000000;
  border-left: 4px solid #dde0e0;
  margin: 16px auto;
}

.post__content iframe {
  display: block;
  width: 100%;
  height: 200px;
  margin: 0 auto;
}

.sidebar {
  width: 100%;
  color: #0000;
  margin: 40px 0 0 0;
}

.signup__container {
  position: relative;
  width: 100%;
}

.signup__container h2 {
  color: #ffffff;
}

.signup__container h2 span {
  display: inline;
  font-size: 40px;
  color: #ffffff;
  margin: 0;
}

.section__title {
  margin: 0 0 32px 0;
  color: #000000;
}

.signup__container h2,
.section__title {
  font-size: 38px;
  font-weight: 600;
}

.signup__container span {
  margin: 24px 0;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;

  display: block;
}

.signup__container form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.signup__container form input {
  height: 44px;
  padding: 0 8px;
  background: #ffffff;
  border: 1px solid #aeadad;
}

.signup__container form input + input {
  margin: 16px 0;
}

.signup__container form input::placeholder {
  font-size: 16px;
  line-height: 1.4;
  color: #20212380;
}

.signup__container form button {
  height: 44px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  background: #ffffff;
  color: #eb7129;
  border: 1px solid #eb7129;
}

.squad1 {
  position: absolute;
  top: -1px;
  left: 0;
}

.squad2 {
  position: absolute;
  bottom: 24px;
  left: 40px;
}

.squad3 {
  position: absolute;
  bottom: 32px;
  left: 50%;
}

.squad4 {
  position: absolute;
  top: 48px;
  right: 38%;
}

.squad5 {
  position: absolute;
  top: 64px;
  right: -1px;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .post__content iframe {
    height: 350px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .post__content iframe {
    height: 525px;
  }
}

@media screen and (min-width: 768px) {
  .signup__container span {
    max-width: 550px;
    width: 100%;
    font-size: 22px;
  }

  .signup__container {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .signup__container h2,
  .section__title {
    font-size: 48px;
  }

  .signup__container h2 span {
    font-size: 48px;
  }

  .signup__container form {
    max-width: 350px;
    width: 100%;
  }

  .signup__container form button {
    width: 160px;
  }

  .items__wrap {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 378px));
    gap: 48px;
  }
}

@media screen and (min-width: 1024px) {
  .post__container {
    flex-direction: row;
    justify-content: center;
  }

  .sidebar {
    width: 100%;
    min-height: 100%;
    max-width: 350px;
    margin: 0 0 0 40px;
    padding: 0 4px 48px 4px;
    display: block;
  }

  .post__content h1 {
    font-size: 40px;
  }

  .post__content h2 {
    max-width: 760px;
  }

  .post__content iframe {
    height: 450px;
  }
}
