.item-container {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 14px;
  color: #202123;
}

.item-dragdrop {
  margin: 0 0 24px 0;
  padding: 8px 16px;

  background: #f6f5f5;
  border: 1px solid #aeadad;
}

.item-dragdrop .editor-class {
  background: #ffffff;
}

.item-title {
  font-size: 14px;
  font-weight: 600;
  color: #202123;
}

.icon-dragdrop {
  margin-right: 8px;
  cursor: pointer;
}

.trash-button {
  padding: 4px 8px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #bb2124;
}

.form-group-my {
  margin: 24px 0;
}

.dragDropContainer {
  height: auto;
  padding: 16px;

  background: #f6f5f5;
  border: 1px solid #aeadad;
}

.accordion-item {
  border-radius: 0px;
  border: 0;
  background: transparent !important;
}

.dragDropContent {
  margin: 0 0 8px 0;
}

.card-item {
  border: 0;
  border-radius: 0px;
  background-color: transparent !important;
}

.card-header-item {
  height: 100% !important;
  padding: 0;
  background: transparent !important;
}

.accordion-button {
  border: 0;
  background: transparent !important;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-button:focus {
  outline: none;
}

.save-button {
  min-width: 75px;
  margin: 16px 0 0 0;
}

.add-item {
  min-width: 160px;
  font-size: 14px;
}

.save-button,
.add-item {
  height: 32px;
  font-weight: 600;
  color: #fff;
  background: #eb7129;
  border: 0;
  transition: filter 180ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
}

.save-button:hover {
  filter: brightness(0.95);
}

.save-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.answer-option {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 500px;
}
