.dropdownButton > div {
  min-width: 120px;
}

.submitButton {
  color: #e85347;
}

.dropdownButton a {
  color: #252c35;
}

.dropdownButton button,
.dropdownButton a {
  width: 100%;
  margin: 0;
  padding: 8px 14px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  border: 0;
  background: none;
}

.dropdownButton button:hover,
.dropdownButton a:hover {
  background: #e6e7e9;
}

@media only screen and (max-width: 768px) {
  .dropdownButton a {
    margin: 0;
  }
}
