.logo-size {
  height: 100%;
  width: 8rem;
  max-height: 96px;
  object-fit: contain;

  margin-left: 48px !important;
  padding: 0.5rem 0;
}

.nk-sidebar {
  max-height: 4.693rem !important;
}
