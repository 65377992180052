.video {
  padding: 6px 14px !important;
  border-radius: 4px !important;

  color: #3c4d62 !important;
  background: #ffffff !important;
  border: 1px solid #dbdfea !important;
  height: 68px;
}

.video .css-hrl1zi-DropDown {
  height: auto !important;
}

.video .styles_thumbnail__nKX7J {
  width: 110px !important;
}

.video .react-dropdown-select-option-label {
  max-width: 100px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  display: block;
}

.video .css-v1jrxw-ContentComponent > span {
  width: 138px;
  padding: 0 8px;
  margin-bottom: 5px;
  font-size: 12px;
  border: 1px solid #eb7129;
  color: #eb7129;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.button-disabled:disabled {
  opacity: 0.5;
}

.info {
  font-size: 18px;
  line-height: 1.3;
}

@media only screen and (min-width: 420px) {
  .video .css-v1jrxw-ContentComponent>span {
    min-width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .video .css-v1jrxw-ContentComponent>span {
    width: auto;
    min-width: 0%;
  }
}

@media only screen and (min-width: 1595px) {
  .video {
    height: 36px;
  }

  .video .css-v1jrxw-ContentComponent>span {
    margin-bottom: 0px;
  }
}
