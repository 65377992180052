.buttonGroup {
  margin: 24px 0 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteButton,
.discardButton,
.saveButton {
  height: 38px;
  font-size: 14px;
  font-weight: 600;
}

.deleteButton,
.discardButton {
  background: #e5e5e5;
}

.deleteButton {
  min-width: 158px;
  color: #bb2124;
  border: 1px solid #bb2124;
}

.buttonGroupContent {
  display: flex;
  align-items: center;
}

.discardButton {
  margin-right: 24px;
  min-width: 180px;
  color: #202123;
  border: 1px solid rgba(32, 33, 35, 0.5);
}

.saveButton {
  min-width: 75px;
  color: #ffffff;
  background: #eb7129;
  border: 1px solid #eb7129;
}

.deleteButton:hover,
.discardButton:hover,
.saveButton:hover {
  filter: brightness(95%);
  transition: filter 180ms ease-in-out;
}

.badge {
  border-radius: 16px;
  font-weight: 600;
}
