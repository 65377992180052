.thanksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  background: url('../../../assets/images/bg/bg-instructor-checkout-thanks.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  color: #ffffff;

  padding-left: 5.5rem;
  padding-right: 5.5rem;
  padding-top: calc(3rem + 5.375rem); /* 96px ou 5.375rem = TAMANHO DA HEADER */
}

.thanksContainer h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 3.125rem;

  color: #ffffff;

  margin-bottom: 4.125rem;
  text-align: center;
}

.thanksContainer h1 span {
  text-transform: uppercase;
  color: #eb7129;
  text-align: center;
  font-weight: 700;
  font-size: 3.125rem;
}

.thanksContainer div {
  font-style: normal;
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 1.5;

  color: #ffffff;

  max-width: 820px;

  margin-bottom: 4.125rem;
  text-align: center;
}

.thanksContainer button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem 1rem;

  height: 2.75rem;

  font-weight: 600;
  text-align: center;
  color: #ffffff;
  line-height: 1.5;
  letter-spacing: 0.02em;
  font-size: 1.25rem;

  vertical-align: middle;
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: #eb7129;

  transition: 0.2s ease-out;
  cursor: pointer;
}

.thanksContainer button:hover {
  filter: brightness(0.8);
}

.thanksContainer button:disabled {
  filter: grayscale();
  cursor: not-allowed;
}

.nk-footer {
  border-top: 0;
}

@media (max-width: 768px) {
  .thanksContainer {
    background: url('../../../assets/images/bg/bg-instructor-checkout-thanks-no-squares.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media (max-width: 576px) {
  .thanksContainer {
    padding: 4rem 2rem 0 2rem;
  }

  .thanksContainer h1 {
    font-size: 2rem;
  }

  .thanksContainer div {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
}
