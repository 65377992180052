.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  grid-area: table;

  border-radius: 0.5rem;
  border: 1px solid rgba(32, 33, 35, 0.25);
  padding: 1rem;
  background-color: #ffffff;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title {
  color: #202123;
  font-size: 1.125rem;
  position: relative;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  margin: 2.75rem 0;
}

.table {
  margin: 0.5rem 0 1rem 0;
}

.table th {
  font-size: 1.125rem;
  padding: 0.5rem 0;
}

.table tr,
.table th {
  border-bottom: 1px solid #20212340;
}

.table td {
  padding: 0.5rem 0;
  text-align: start;

  font-size: 1rem;
}

.table a {
  text-decoration: underline;
  font-weight: 700;
  color: #8eaae9;
}
