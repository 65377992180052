.container {
  display: flex;
  align-items: center;
  margin: 24px 0;
  flex-wrap: wrap;
}

.container h5 {
  margin: 0 4px 0 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.8;
  color: #202123;
}

.socialLinksItem {
  display: flex;
}

.socialLinksItem li {
  margin: 0 4px;
  padding: 8px;
  border-radius: 50%;
  background: #eb7129;

  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 1;

  flex-shrink: 0;
}

.socialLinksItem li a {
  margin: 0;
}

.socialLinksItem li a img {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
