.rdrNextPrevButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 0 0.35rem;
  padding: 0;
  border: 0;
  border-radius: 100%;
  background: transparent;
  color: #202123;
  font-size: 20px;
  z-index: 9999;
}

.rdrNextPrevButton:hover {
  background: #edf2f7;
}

.rdrMonthName {
  padding: 0;
  margin: -25px 0 0 0;
  text-transform: capitalize;
}
.rdrNextPrevButton .rdrPprevButton i {
  color: #202123;
}
