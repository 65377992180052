.bedge {
  width: 100%;
  padding: 8px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;

  color: #ffffff;
  border-radius: 20px;
}
