.input {
  display: flex;

  color: rgba(0, 0, 0, 0.49);
  border-radius: 0;
  border: 1px solid rgba(32, 33, 35, 0.25);
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.input::placeholder {
  color: rgba(32, 33, 35, 0.5);
}

@media (max-width: 768px) {
  .input {
    padding: 0 0.5rem;
  }

  .input::placeholder {
    font-size: 0.75rem;
  }
}
