.brand {
  max-height: 1.9rem;
  max-width: 10rem;
  margin: 0 1rem;
  object-fit: contain;
}

@media screen and (max-width: 441px) {
  .brand {
    height: 25px;
  }
}

.section {
  /* padding-top: 10px; */
  padding-bottom: 10px;
  border-bottom: 1px solid #8091a7 !important;
}

.container_anterior_proximo {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-left: 20px;
}

.container_name_course {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  padding-left: 25px;
  padding-right: 10px;
}

.arrow_icon {
  width: 30px;
  height: 30px;
  color: black;
}

.container_view_text {
  justify-content: center;
}

.container_geral {
  overflow-x: hidden;
  padding-top: calc(100px);
}

.div_view_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view_content {
  margin: 15px 15px 15px 15px;
  text-align: justify;
}

.exibe_video {
  margin: 0 15px 0 15px;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.div_concluido {
  margin: 0 0 15px 15px;
}

.concluido {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.nameCourse {
  padding: 4px;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 600;
}

.moduleList {
  padding-top: 40px;
  padding-left: 16px;

  width: 40%;

  position: relative;
  overflow: hidden scroll;
}

.moduleContainer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.moduleContent {
  display: flex;
  width: 100%;

  border-left: 1px solid #8091a7;
}

@media screen and (max-width: 768px) {
  .container_geral {
    overflow-x: hidden;
    padding-top: calc(55px);
  }

  .moduleList {
    max-height: 400px !important;
  }
}

@media screen and (max-width: 1024px) {
  .container_name_course a {
    margin: 10px 0;
  }

  .arrow_icon {
    width: 20px;
    height: 20px;
  }

  .nameCourse {
    font-size: 16px;
  }

  .moduleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .moduleList {
    margin-top: 20px;
  }

  .moduleContent {
    order: -1;
    margin-left: 0;

    border-left: 0;
  }

  .moduleList {
    margin-top: 20px;
    padding: 0 24px;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    /* overflow-y: scroll;
    height: 100%; */

    max-height: 550px;
  }
}

@media screen and (min-width: 1024px) {
  .moduleContent {
    padding-top: 40px;
  }
}

.moduleList::-webkit-scrollbar {
  max-height: 10px;
  width: 4px;
}

.moduleList::-webkit-scrollbar-thumb {
  background: #20212380;
  padding: 2px;
  border-radius: 4px;
}

.moduleList::-webkit-scrollbar-track {
  background: #f5f6fa;
}

.thanksContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 15rem 0;
}

.thanksContainer h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 3.125rem;

  color: black;

  margin-bottom: 4.125rem;
  text-align: center;
}

.thanksContainer h1 span {
  text-transform: uppercase;
  color: #eb7129;
  text-align: center;
  font-weight: 700;
  font-size: 3.125rem;
}

.thanksContainer div {
  font-style: normal;
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 1.5;

  color: black;

  max-width: 820px;

  margin-bottom: 4.125rem;
  text-align: center;
}

.thanksContainer button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem 1rem;

  height: 2.75rem;

  font-weight: 600;
  text-align: center;
  color: black;
  line-height: 1.5;
  letter-spacing: 0.02em;
  font-size: 1.25rem;

  vertical-align: middle;
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: #eb7129;

  transition: 0.2s;
  cursor: pointer;
}

.thanksContainer button:hover {
  filter: brightness(0.8);
}

.thanksContainer button:disabled {
  filter: grayscale();
  cursor: not-allowed;
}

.courseName {
  color: #eb7129;
  font-size: 1.875rem;
}

.support {
  margin-top: 2rem;
}

.support p {
  font-size: 1rem;
}