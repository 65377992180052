.chakra-react-select {
  background: #f6f5f5;
}

.chakra-react-select {
  color: rgba(0, 0, 0, 0.49);
}

.chakra-react-select .css-mt2q39 {
  border-radius: 0;
  border: 1px solid rgba(174, 173, 173, 1);
}

.chakra-react-select .css-mt2q39:hover {
  border-color: rgba(174, 173, 173, 1);
}

.chakra-react-select .css-mt2q39:focus,
.chakra-react-select .css-mt2q39[data-focus] {
  border-color: #eb7129;
  box-shadow: 0 0 0 1px #eb7129;
}

.chakra-react-select .css-1puyje9 {
  margin: 0;
  color: rgba(0, 0, 0, 0.49);
}

.chakra-react-select .css-rnauqt {
  background: #f6f5f5;
  padding: 0.5rem;
}
