.container {
  display: flex;
  gap: 0.25rem;

  margin-bottom: 1.5rem;
}

.container div:first-child {
  align-self: flex-start;
}

.container div:first-child svg {
  color: #eb7129;

  font-size: 1.5rem;
}

.container div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-top: 0.25rem;
}

.container div:last-child span,
.container div:last-child p {
  font-size: 0.75rem;
}
