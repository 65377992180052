.sectionContainer {
  display: flex;
  align-items: center;
  flex: 1;

  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.5);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  padding: 1rem;
  margin-bottom: 0.5rem;
}

.sectionContainer div:last-child {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.sectionContainer div:last-child h6 {
  margin: 0 0.5rem 0 0;
}

.dragContainer {
  margin-right: 1.5rem;
  cursor: pointer;
}
