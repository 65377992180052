.homeSections:nth-child(2) {
  margin-top: calc(6.25rem - 3rem);
}

.bannerSection,
.videoSection,
.blogSection {
  margin-bottom: 100px;
  overflow: hidden;
}

.videoContainer {
  padding: 0 48px;
}

.bannerSection > div {
  margin-top: 100px;
  width: 100%;
}

.cardContainer {
  margin: 2rem 2rem;
}

.cardContent {
  height: 126px;
  max-height: 126px;
  padding: 10px 20px 30px;

  display: flex;
  flex-direction: column;
  align-items: initial;
}

.cardContent > span,
.cardContent > small {
  margin-bottom: 8px;
}

.cardContent > span {
  height: 50px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  color: #202123;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardContent > small {
  font-size: 14px;
  font-weight: 500;
  color: #202123;
}

.cardProducts > img {
  object-fit: cover;
  width: 100%;
  height: 256px;
}

.tagsGroup {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.gridProducts {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(0px, 356px));
  gap: 60px;
}

.footerSection {
  background: #202123;
  padding: 75px 48px;
}

.footerContainer {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(400px, max-content));
  grid-gap: 40px;
  gap: 40px;
}

.footerTitle {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold !important;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.footerParagraph {
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
}

.phone,
.email {
  font-size: 16px;
  color: #ffffff;
}

.subFooter {
  margin-top: 70px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subFooter > small {
  font-size: 16px;
  color: #ffffff;
}

.socialNetworks a:last-child {
  margin-left: 14px;
}

.defaultImage {
  width: 100%;
  height: 277px;
  border-radius: 30px 30px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultImage span {
  font-size: 72px;
  font-weight: 900;
  color: #ffffff;
}

.prevArrow,
.nextArrow,
.prevArrowCatProd,
.nextArrowCatProd {
  text-align: center;
  border: none !important;
  background: transparent !important;
  cursor: pointer !important;
  margin: 10px;
}

.prevArrow {
  left: 5px !important;
}

.nextArrow {
  right: 5px !important;
}

.prevArrowCatProd {
  left: -40px;
}

.nextArrowCatProd {
  right: -40px;
}

.bgImage {
  min-width: 100%;
  height: 300px;

  background-size: cover;
  background-position: center center;
}

@media only screen and (min-width: 1280px) {
  .homeSections:nth-child(2) {
    margin-top: calc(6.25rem);
  }
}

@media only screen and (max-width: 768px) {
  .prevArrow,
  .nextArrow,
  .prevArrowCatProd,
  .nextArrowCatProd {
    width: 40px !important;
    height: 40px !important;

    background: none !important;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
  }

  .prevArrowCatProd {
    left: -12px;
  }

  .nextArrowCatProd {
    right: -12px;
  }

  .prevArrow > svg,
  .nextArrow > svg,
  .prevArrowCatProd > svg,
  .nextArrowCatProd > svg {
    font-size: 40px !important;
    flex-shrink: 0;
  }

  .bannerItem {
    margin-top: 50px;
    height: 250px;
  }

  .defaultImage {
    width: 100%;
    height: 250px;
  }

  .bannerSection > div {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .cardContent > span {
    font-size: 18px;
  }
}
