.rhap_container {
  font-family: 'Montserrat', sans-serif;

  min-height: 64px;

  border-radius: 8px;
}

.rhap_controls-section {
  flex: none;
}

.rhap_main-controls-button {
  color: black;
}
.rhap_play-pause-button {
  color: #eb7129;
}

.rhap_progress-filled {
  background-color: #ec7117;
}

.rhap_progress-indicator {
  background: #202123;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
  width: 12px;
  height: 12px;
  margin-left: -10px;
  top: -4px;
}

.rhap_time {
  display: flex;
  color: #909091;

  width: 3rem;
}

.rhap_download-progress {
  background-color: #c7c7c8;
  height: 6px;
}
