.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 4rem;
  text-align: center;
}

.noDataContainer img {
  height: 6rem;
  margin-bottom: 2rem;
  opacity: 0.56;
  pointer-events: none;
}

.noDataContainer h6 {
  font-weight: 600 !important;

  margin-bottom: 1rem;
}
