.section-banner {
  display: flex;
  flex-direction: column;
}

.banner {
  width: 100%;
  object-fit: contain;
}

.bg-dark {
  background-color: #191b1f;
}

.lead {
  font-size: 17px;
}

.read-more-link {
  cursor: pointer;
  font-size: 19px;
  color: #0964c5 !important;
}

.section-content-course {
  margin: 4rem 0rem 3.875rem;
}

.section-content-purchase {
  background: #202123;
  padding: 3.125rem 0;
}

.heading-title {
  margin: 2rem 0;
}

.spacing-heading-title {
  margin: 7rem 0 2rem 0;
}

.heading-title > span,
.spacing-heading-title > span,
.heading-price {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1;
  color: #202123;
}

.heading-content,
.heading-faq {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 1.5rem;
}

.container-heading {
  margin-bottom: 4rem;
}

.price {
  text-align: center;
  font-size: 3rem;
}

.installments {
  text-align: center;
  font-size: 2.25rem;
}

.section-faq {
  margin: 0 0 3.875rem;
}

.button-purchase {
  margin: 0 !important;
  min-width: 25rem;
  min-height: 3.875rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.button-purchase > span {
  font-weight: 600;
  font-size: 1.8rem;
}

.content-course h1 {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 400;
  color: #202123;
}

.content-course h2 {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 400;
  color: #202123;
}

.content-course h3 {
  font-size: 1.15rem;
  line-height: 1.4;
  font-weight: 400;
  color: #202123;
}

.content-course p {
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
  color: #202123;
}

.content-course p:last-child {
  margin-top: -1.3rem !important;
}

.content-course iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.course-date {
  font-size: 19px;
  color: #000000;
  font-weight: 300;
}

.container-price {
  border-radius: 15px !important;
}

.container-purchase-button {
  margin-top: 3.125rem;
}

.section-description ol,
.section-description ul {
  list-style: disc;
}

@media (min-width: 320px) and (max-width: 480px) {
  .flex-md-column-2 {
    flex-direction: column !important;
  }
  .container-price h5 {
    margin-bottom: 10px;
    text-align: center;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .heading-title > span,
  .spacing-heading-title > span,
  .heading-price {
    font-size: 2rem !important;
    margin: inherit;
  }

  .section-content-course {
    margin-bottom: 0;
  }

  .spacing-heading-title {
    margin: 4.25rem 0 2rem 0;
  }

  .heading-faq {
    font-size: 1.5rem;
  }

  .container-purchase-button {
    margin-top: 2rem;
  }

  .button-purchase {
    min-width: 20rem;
  }

  .content-course p {
    font-size: 1.125rem !important;
  }
}

@media screen and (max-width: 576px) {
  .installments {
    font-size: 1.5rem;
  }
}
