.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;

  padding: 0.2rem 0;
  margin: 1rem 0;
}

.cardsContainer img {
  max-width: 2.5rem;
}

@media (max-width: 576px) {
  .container footer h4 {
    font-size: 0.75rem;
  }

  .cardsContainer {
    justify-content: space-between;

    gap: 0;
  }
}
