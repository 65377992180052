.container {
  height: 100%;
  width: 100%;

  padding: 45px 40px;
}

.content header > h2 {
  line-height: 1.4;
  color: #202123;
}

.content form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
}

.column__left,
.column__right {
  padding: 0px;
}

.column__left {
  width: 400px;
  flex-direction: column;
  margin-right: 60px;
}

.field {
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
}

.field label,
.checkbox__group label,
.field__header span {
  color: #202123;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;

  display: block;
}

.field input,
select {
  height: 40px;
  padding: 0 16px;
  font-size: 14px;
  background: #f6f5f5;
  border-radius: 4px;
  border: 1px solid #aeadad;
  box-sizing: border-box;
}

.field__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preco {
  margin-bottom: 50px;

  display: flex;
  align-items: baseline;
  justify-content: center;
}

.symbol {
  font-size: 16px;
  font-weight: 700;
}

.value {
  font-weight: 700;
  font-size: 64px;
}

:is(.symbol, .value, .value__float) {
  color: #eb7129;
  line-height: 1.2;
}

.field .input__ranger[type='range']:focus {
  outline: none;
}

.field .input__ranger[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  margin: 15px 0;
  padding: 0;
  background: #d3d3d3;
  border: 0;
}

.field .input__ranger[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.field .input__ranger[type='range']::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 15px;
  background: #eb7129;
  cursor: pointer;
  -webkit-appearance: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.checkbox__group {
  display: flex;
  align-self: flex-start;
}

.checkbox__group > label {
  color: #202123;
  font-size: 16px;

  display: block;
}

.checkbox__group > div {
  display: flex;
  align-items: baseline;
}

.checkbox__group > div + div {
  margin-left: 20px;
}

.checkbox__group div input[type='radio'],
input[type='checkbox'] {
  margin-right: 3px;
  background: #eb7129;
  border-radius: 10px !important;
}

.table__container {
  margin-top: 0;
}

.table {
  width: 100%;
}

.table h4 {
  color: #202123;
}

.table thead,
.header {
  background: #dcdcdc;
}

.header th {
  border-bottom: none;
}

.header {
  font-size: 16px;
  line-height: 32px;
}

.table thead tr th {
  border-bottom: none;
}

.table tbody tr td:first-child,
.header th:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.table tbody tr td {
  width: 340px;
  font-size: 16px;
  color: #202123;
  text-align: center;
}

.table tbody tr td {
  padding: 12px 90px;
  font-weight: 400;
  vertical-align: middle;
}

.table tbody tr th {
  padding: 5px;
  color: #202123;
  text-align: center;
}

.button__group {
  display: block;
  text-align: end;
}

.button__group a {
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;

  background: #e5e5e5;
  color: #202123;
  border: 1px solid rgba(32, 33, 35, 0.5);
  transition: background 180ms ease-in-out;
}

.button__group a:hover {
  background: #dddddd;
}

.button__group button {
  padding: 7px 56px;
  margin-left: 18px;
  font-size: 16px;
  font-weight: 600;

  border-radius: 1px solid #000;

  background: #eb7129;
  color: #ffffff;
  border: 0;
  transition: background 180ms ease-in-out;
}

.button__group button:hover {
  background: #e56215;
}

.checkBoxShowPlan {
  display: flex;
  margin: 0.1rem 0;
}

.checkBoxShowPlan > label {
  font-size: 16px;
  font-weight: 600;
  color: #202123;
}

.tip b {
  color: #eb7129;
}

@media only screen and (max-width: 992px) {
  .container {
    margin-top: 60px;
  }

  .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .table__container {
    margin-top: 60px !important;
  }

  .table {
    width: 100%;
  }

  .column__left {
    margin-right: 0;
  }

  .column__left,
  .column__right {
    width: 100% !important;
  }

  .button__group {
    text-align: center;
  }
}
