.plans__container {
  height: 100%;
  width: 100%;
}

.plans__content {
  padding: 45px 40px;
}

.plans__content header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plans__content header h1 {
  line-height: 1.4;
  color: #202123;

  display: block;
}

.plans__content header a {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 600;

  background: #eb7129;
  color: #ffffff;
  transition: background 180ms ease-in-out;

  display: flex;
  align-items: center;
}

.plans__content header a:hover {
  background: #e56215;
}

.plans__content header a svg {
  stroke-width: 3px;
  margin-right: 7px;
}

.table__plans {
  width: 100%;
}

.table__plans thead tr,
.table__plans tbody tr {
  border-bottom: 2px solid rgba(32, 33, 35, 0.25);
}

.table__plans thead tr th,
.table__plans tbody tr td {
  padding: 24px 0;
}

.table__plans thead tr th {
  font-size: 20px;
  color: #202123;
  opacity: 0.5;
}

.table__plans {
  width: 100%;
}

.table__plans tbody tr td {
  color: #202123;
  font-weight: 600;
  font-size: 18px;
}

.td__group {
  text-align: center;
}

.btn-group {
  border-radius: 0 !important;
}

.status {
  text-align: center;
}

.toast__container {
  display: flex;
  align-items: center;
}

.toast__container span:last-child {
  margin-left: 20px;
}

.warning {
  height: 300px;
  opacity: 0.8;
  flex-direction: column;
}

.warning > div {
  margin: 30px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.warning div > svg {
  height: 120px;
  width: 120px;
}

.warning div > span {
  font-size: 20px;
  font-weight: 600;
  color: #202123;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
  margin: 0;
  background-color: transparent;
  color: #20212380;
  box-shadow: none;
  border: 0;
  transition: opacity 0.2s ease-in-out;
  padding: 0.7rem 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}

.copyButton:hover {
  opacity: 0.75;
}

.copyButton:active {
  border: 0;
  outline: 0;
}

.copyButton svg {
  flex-shrink: 0;
}