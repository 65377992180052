.containerSkeleton {
  margin: 0 0 24px 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.imageSkeleton,
.titleSkeleton,
.usernameSkeleton,
.tagSkeleton {
  position: relative;
  overflow: hidden;
}

.imageSkeleton {
  width: 100%;
  height: 190px;
  background: #d9d9d9;
  border-radius: 10px 10px 0 0;
}

.contentSkeleton {
  padding: 16px;

  display: flex;
  flex-direction: column;
}

.titleSkeleton,
.usernameSkeleton {
  margin-bottom: 16px;
}

.titleSkeleton {
  width: 100%;
  height: 27px;
  background: #d9d9d9;
}

.usernameSkeleton {
  width: 80px;
  height: 27px;
  background: #d9d9d9;
}

.tagsGroupSkeleton {
  display: flex;
  justify-content: space-between;
}

.tagSkeleton {
  width: 120px;
  height: 27px;
  background: #d9d9d9;
}

.tagSkeleton + .tagSkeleton {
  margin-left: 12px;
}

.imageSkeleton::after,
.titleSkeleton::after,
.usernameSkeleton::after,
.tagSkeleton::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    #d9d9d9 0%,
    rgba(0, 0, 0, 0.05) 20%,
    #d9d9d9 40%,
    #d9d9d9 100%
  );
  background-size: 450px 400px;
  background-repeat: no-repeat;
  animation: shimmer 1s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}
