.underline {
  text-decoration: underline;
}

.cardTrasaction {
  padding: 24px;
  border: 1px solid #202123;
  border-radius: 4px;
  transition: border 180ms ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentGroup {
  line-height: 20px;
}

.transaction {
  margin-bottom: 24px;

  display: flex;
  text-align: center;
}

.price {
  font-size: 32px;
  font-weight: 600;
}

.textTransaction {
  font-size: 14px;
  color: #202123;
}

.buttonGroup {
  display: flex;
  align-items: center;
}

.buttonGroup > button + button {
  margin-left: 8px;
  flex-shrink: 0;
}

.reverseButton,
.exportButton {
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  background: #e5e5e5;
  color: rgba(32, 33, 35, 0.5);
  border-radius: 0;
  border: 1px solid rgba(32, 33, 35, 0.5);

  display: flex;
  justify-content: space-between;
  align-items: center;

  vertical-align: middle;
  line-height: 18px;
}

.reverseButton:disabled,
.exportButton:disabled {
  cursor: not-allowed;
}

.reverseButton > svg,
.exportButton > svg {
  margin-right: 4px;
}

.bagdeValue {
  padding: 16px 32px;
  border-radius: 4px;
  background: #ffffff;
  color: #eb7129;
  border: 1px solid #eb7129;
}

.table {
  border: 1px solid #eb7129 !important;
  display: table-caption !important;
  overflow-x: hidden;
}

.table + .table {
  margin-top: 80px;
}

.table thead {
  padding: 0 10px;
  line-height: 40px;
  background: #eb7129;
  color: #ffffff;

  display: flex;
  align-items: center;
}

.table thead > svg {
  margin-right: 5px;
}

.tableTd {
  padding: 8px 16px !important;
  text-align: center;
  vertical-align: middle;
}

.table tbody td {
  color: #eb7129;
  border-right: 1px solid #eb7129;
  border-top: 1px solid #eb7129;
}

.table tbody td:last-child {
  border-right: none;
}

.grid {
  width: 100%;
  margin: 0 0 20px 0;
  position: relative;

  display: grid;
  grid-template-columns: 200px 200px 360px;
  grid-template-areas: 'column01 column02 column03';
  grid-gap: 40px;
}

.column01 {
  grid-area: column01;
}

.column02 {
  grid-area: column02;
}

.column03 {
  grid-area: column03;
  align-items: flex-end;
}

.spacing {
  min-height: 90%;
}

.line01,
.line02,
.line03,
.line04 {
  position: absolute;
  background: #c4c4c4;
  z-index: -1;
}

.line01 {
  top: 20%;
  right: -30%;
  min-width: 80%;
  height: 2px;
}

.line02 {
  top: 18%;
  right: 76%;
  width: 2px;
  min-height: 80%;
}

.line03 {
  top: 22%;
  right: -20%;
  min-width: 100%;
  height: 2px;
}

.line04 {
  bottom: 8%;
  right: -20%;
  min-width: 100%;
  height: 2px;
}

.backButton {
  display: flex;
  max-width: 350px;
}

.backButton span {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  vertical-align: middle;
  color: #202123;
}

.backButton svg {
  margin: 0 8px 0 0;
}

.gridTransactionDetail {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.heading {
  display: flex;
  align-items: center;
}

.heading span,
.boxHeading {
  font-size: 18px;
  font-weight: 400;
  color: #202123;
  line-height: 1.2;
}

.texting {
  font-size: 20px;
  font-weight: 600;
  color: #202123;
  line-height: 1.2;
}

.colDetail {
  display: flex;
  flex-direction: column;
}

.gridBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}

.buttonGroup {
  height: 100%;
  display: flex;
}

.button {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  font-size: 18px;
  font-weight: 500;

  border: 1px solid #eb7129;
  background: none;
  color: #eb7129;

  transition: background 180ms ease-in-out;
}

.button img {
  margin-right: 8px;
  flex-shrink: 0;
}

.button:disabled {
  background-color: #E5E5E5;
  color: #a9a9a9;
  border-color: #a9a9a9;
  cursor: not-allowed;
}

.price {
  margin-top: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;

  color: #eb7129;
}

.boxHeader,
.boxBody,
.boxFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 100%;
}

.boxHeader a {
  color: #eb7129;
  text-decoration: underline;
  margin: 0;
}

.boxHeader svg {
  color: #202123;

  width: 20px;
  height: 20px;
}

.boxBody {
  margin: 16px 0;
}

.boxBody span,
.boxBody p {
  margin-top: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;

  color: #eb7129;

  word-wrap: break-word;
  overflow: hidden;
}

.boxBody span {
  margin-bottom: 8px;
}

.boxHeader img {
  width: 48px;
  height: 40px;
  flex-shrink: 0;
}

.buttonBoxFooter {
  border: none;
  background: transparent;
  color: #eb7129;
  text-decoration: underline;
}

.copy {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px;
  background: #202123;
  color: #ffffff;
  border-radius: 4px;
}

.ratesGroup {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.textingFooter,
.rates {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #202123;
}

.headingModal {
  margin: 15px;
  color: #eb7129;
  letter-spacing: 1px;
  font-size: 1.8em;
}

.boxModal {
  margin: 15px;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.modalFooterButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.buttonModal {
  background-color: #eb7129;
  color: #e5e5e5;
  border: none;
  height: 35px;
  min-width: 140px;
  font-size: 16px;
  font-weight: 800;
  margin-right: 20px;
  padding: 0 1rem;
}

.imgBrand > img {
  width: 60px;
  height: 35px;
}

.buttonModalCancel {
  background-color: #a9a9a9;
  color: #e5e5e5;
  border: none;
  height: 35px;
  min-width: 140px;
  font-size: 16px;
  font-weight: 800;
  margin-right: 20px;
}

.detailsTransaction {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.header,
.subHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subHeading {
  margin: 16px 0;
  font-size: 16px;
  font-weight: 400;
  color: #202123;
}

.badgeContainer > span {
  width: 180px;
}

.priceHeader {
  font-weight: 600;
  font-size: 32px;
  color: #eb7129;
}

.gridDetails {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 450px));
  grid-template-rows: repeat(auto-fit, minmax(250px));
  gap: 16px;
}

.gridDetails ul li {
  margin-bottom: 16px;
}

.gridDetails ul li h6,
.gridDetails ul li span {
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 1.4;
}

@media (max-width: 920px) {
  .cardTrasaction,
  .transaction {
    flex-direction: column !important;
  }

  .transaction {
    justify-content: center;
  }

  .leftSpacing {
    margin-top: 16px;
    margin-left: 0;
  }

  .column {
    flex-direction: column;
  }

  .marginNone {
    margin-left: 0 !important;
    margin-top: 24px;
  }
}

@media (max-width: 768px) {
  .grid {
    overflow-x: scroll;
  }

  .grid::-webkit-scrollbar {
    height: 10px;
    width: 5px;
  }

  .grid::-webkit-scrollbar-thumb {
    padding: 2px;
    border-radius: 8px;
    background-color: #a9a9a9;
  }

  .grid::-webkit-scrollbar-track {
    background-color: #e5e5e5;
    border-radius: 8px;
  }
}

@media (max-width: 576px) {
  .backButton {
    font-size: 20px;
  }

  .buttonGroup {
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .buttonGroup button {
    width: 100%;
  }

  .buttonGroup button + button {
    margin: 8px 0 0 0 !important;
  }

  .heading,
  .priceHeader {
    font-size: 16px;
  }

  .gridTransactionDetail {
    grid-template-columns: repeat(auto-fill, minmax(100%, 100%));
  }
}
