.videoItem {
  padding: 10px;
  display: flex;
  align-items: center;
}

.videoItem div:first-child {
  margin-right: 10px;
  height: 60px;
}

.videoItem span {
  font-size: 12px;
  color: '#ccc'
}

.thumbnail {
  width: 7rem;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}
