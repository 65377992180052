.container {
  display: flex;
  flex-direction: column;
}

.container h5 {
  margin-top: 2rem;
}

.container p {
  color: #20212380;
  font-size: 1.125rem;
}

.container p a {
  color: #eb7129 !important;
  font-size: 1.125rem;

  text-decoration: underline;
}

.container input {
  margin-top: 1rem;

  display: flex;
  width: 100%;

  font-size: 0.875rem;
  color: #aeadad;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
}

.copyInput {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  width: 100%;

  font-size: 0.875rem;
  color: #aeadad;
  padding: 0.5rem 1rem;
  background: #20212326;
  border-radius: 4px;
  margin-bottom: 2rem;

  font-family: monospace;
}

.copyInput:focus {
  border: 2px solid #eb7129;
}

.copyInput div:first-child {
  display: flex;
  align-items: center;
}

.copyInput p {
  font-size: 0.875rem;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
  margin: 0;
  background-color: transparent;
  color: #20212380;
  box-shadow: none;
  border: 0;
  transition: opacity 0.2s ease-in-out;
}

.copyButton:hover {
  opacity: 0.75;
}

.copyButton:active {
  border: 0;
  outline: 0;
}

.copyButton svg {
  flex-shrink: 0;
}

.container form {
  display: flex;
  flex-direction: column;
}

.container form input {
  margin-top: 1rem;

  display: flex;
  width: 100%;

  font-size: 0.875rem;
  color: #aeadad;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
}

.container form input {
  outline: none;
  margin-top: 1rem;

  display: flex;
  width: 100%;

  font-size: 0.875rem;
  color: #aeadad;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
}

.container form input:focus {
  outline: 1px solid #eb7129;
}

.container form input:hover:not(:focus) {
  outline: 1px solid #6e6d6d;
  border: 1px solid transparent;
}

.container button:disabled {
  pointer-events: none;
}

.formActions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  gap: 0.5rem;
}

.formActions div:last-child {
  display: flex;

  gap: 0.5rem;
}

.formActions button.cancelButton {
  color: #202123;
  background: #e5e5e5;
}

@media screen and (min-width: 1400px) {
  .imageWrapper {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .imageWrapper {
    overflow: visible;
    width: 100%;
  }

  .imageWrapper img {
    border-radius: 20px 20px 0 0;
    overflow: visible;
  }

  .container form {
    margin-top: 1rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .formWrapper form p {
    font-size: 0.875rem;
  }
}
