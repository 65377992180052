.thumbnailContainer {
  display: flex;

  margin-bottom: 1rem;
}

.thumbnailContainer form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.thumbnailContainer form > div:first-child {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;

  margin-bottom: 1rem;
}

.description {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.thumbnailContainer h4 {
  font-size: 1.25rem;
  margin-bottom: 0.875rem;
}

.thumbnailContainer p {
  font-size: 1rem;
  color: rgba(32, 33, 35, 0.5);

  margin-bottom: 0.75rem;
}

.thumbnail {
  flex: 1;
}
