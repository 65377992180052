.input__password {
  width: 100%;
  padding: 11px 16px;
  background: #ffffff;
  border: 1px solid #dbdfea;
  border-radius: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input__password:focus-within {
  box-shadow: none;
  border-color: #eb7129;
  box-shadow: 0 0 0 3px rgb(101 118 255 / 10%);
}

.input__password input {
  flex: 1;
  padding: 0;
  background: transparent;
  border: 0;
}

.input__password input::placeholder {
  color: #c0cee7;
}

.input__password input:focus {
  border-color: none;
  outline: none;
}

.input__password button {
  border: 0;
  padding: 0 4px;
  background: none;
  margin-left: 20px;
}

.input__password button svg {
  width: 16px;
  height: 16px;
  color: #acb9cf;
}
