.aboutSection {
  display: flex;
}

.mediasSection {
  align-items: center;

  padding: 4rem 3rem;

  overflow: hidden;

}

.heading {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  white-space: pre-wrap;
  word-wrap: break-word;

  gap: 1rem;
}

.sectionTitle {
  font-size: 2.5rem;
  line-height: 1.25;
}

.heading p {
  font-size: 1.125rem;
}

.mediaWrapper {
  flex: 1.675;
}

.videoWrapper {
  flex: 1;
  width: 100%;

  aspect-ratio: 16/9;

  border: none;
}

@media only screen and (max-width: 576px) {
  .mediasSection {
    padding: 0.25rem 0rem;

    display: flex;
  }

  .sectionTitle {
    font-size: 2rem !important;
  }
  .videoWrapper {
    flex: 1;
    height: fit-content;
    width: 100vw;
    aspect-ratio: 16/9;

    border: none;
  }

  .aboutSection {
    flex-direction: column;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .mediasSection {
    flex-direction: column;
    justify-content: center;

    gap: 3rem;

    padding: 4rem 4rem;
    align-items: unset;
  }

  .aboutSection {
    flex-direction: column;
  }
}
