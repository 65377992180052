.sectionContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  padding: 1.5rem 3rem;
  margin-bottom: 2.5rem;
}

.sectionHeader {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  position: relative;
}

.sectionHeader div:first-child {
  display: flex;
  align-items: center;
}

.duplicateButton,
.deleteButton {
  position: relative;
  padding: 0 !important;
  border: 0;
  background: none !important;
}

.tooltipButton {
  position: absolute;

  min-width: 200px;
  padding: 8px 16px;

  top: -1rem;
  right: 2.5rem;

  background: #eb7129;
  border-radius: 4px;

  opacity: 0;
  visibility: hidden;

  text-align: center;
}

.tooltipButton::after {
  content: '';
  position: absolute;
  top: 1.2rem;
  right: -0.7rem;
  width: 0;
  height: 0;

  border-left: 8px solid #eb7129;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;

  transform: translateX(-50%);
  z-index: 12;
}

.tooltipButton p {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #ffffff;

  z-index: 10;
}

.duplicateButton:hover div,
.deleteButton:hover div {
  opacity: 1;
  visibility: visible;
}

.duplicateButton svg {
  margin-right: 0 !important;
}

.duplicateButton:disabled,
.deleteButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sectionHeader h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

.dragContainer {
  position: absolute;
  left: -24px;
  cursor: pointer;
}

.actionsHeaderContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actionsHeaderContainer p {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.actionsHeaderContainer select {
  border-radius: 0;

  height: auto;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  font-size: 14px;
  background: #f6f5f5;
  border-radius: 0;
  border: 1px solid #aeadad;
  box-sizing: border-box;

  color: #0000007d;
}

.sectionDescription {
  margin-bottom: 2.5rem;
}

.sectionDescription p {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5;

  color: #20212380;
}

.sectionAction {
  display: flex;

  justify-content: center;
  margin-bottom: 1.5rem;
}
