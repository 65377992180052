.container {
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
  border: 1px solid rgba(32, 33, 35, 0.25);
  padding: 1rem 1rem 0.25rem 1rem;
  background-color: #ffffff;
}

.container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.75rem;
  background: currentColor;
  border-radius: 0.5rem 0.5rem 0 0;
}

.container span {
  display: block;
}

.title {
  color: #202123;
  font-size: 1.125rem;
}

.data {
  color: currentColor;
  font-size: 1.5rem;
  font-weight: 700;
}
