.headerContainer {
  display: flex;

  margin-bottom: 1rem;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.headerContainer form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.headerContainer form > div:first-child {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 1rem;
}

.headerContainer h4 {
  font-size: 1.25rem;
  margin-bottom: 0.875rem;
}

.headerContainer p {
  font-size: 1rem;
  color: rgba(32, 33, 35, 0.5);

  margin-bottom: 0.75rem;
}

.headerLogo {
  flex: 1;

  margin-right: 4rem;
}

.headerLogo h4 {
  margin-bottom: 1.25rem;
}

.logoWrapper {
  object-fit: contain;
  width: 256px;
  height: 256px;

  margin-bottom: 1rem;
}

.headerLogoButton {
  color: #666666 !important;
  background-color: #e5e5e5 !important;
  transition: 0.2s;
  font-size: 0.875rem !important;
}

.headerLogoButton:hover {
  color: #666666 !important;
  filter: brightness(90%);
}

.headerMenus {
  flex: 1;
}

.headerMenus div {
  display: flex;
  flex-wrap: wrap;

  gap: 1rem;
}

.headerMenus div div {
  margin-right: 0.25rem;
}

.headerMenus label {
  font-size: 1rem;
  margin: 0;

  color: #202123;
}

.formFooter {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.formFooter button {
  min-width: 8.5rem;
  min-height: 1.5rem;
  font-size: 0.75rem !important;
}

.formFooter button:first-child {
  margin-right: 1rem;
  color: #202123;

  background: #e5e5e5;
  border: 1px solid rgba(32, 33, 35, 0.5);
}
