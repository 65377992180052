.cardContent {
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: initial;
}

.cardContent > span {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 24px;

  color: #202123;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardContent > small {
  font-size: 14px;
  font-weight: 500;
  color: #202123;
}

.cardProducts > img {
  object-fit: cover;
  width: 100%;
  height: 12rem;
}

.tagsGroup {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.defaultImage {
  object-fit: cover;
  width: 100%;
  height: 12rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultImage span {
  font-size: 72px;
  font-weight: 900;
  color: #ffffff;
}

.prevArrow,
.nextArrow,
.prevArrowCatProd,
.nextArrowCatProd {
  text-align: center;
  border: none !important;
  background: transparent !important;
  cursor: pointer !important;
}

.prevArrow {
  left: 5px !important;
}

.nextArrow {
  right: 5px !important;
}

.prevArrowCatProd {
  left: -40px;
}

.nextArrowCatProd {
  right: -40px;
}

@media only screen and (max-width: 768px) {
  .cardContainer {
    margin-right: 0;
  }

  .prevArrow,
  .nextArrow,
  .prevArrowCatProd,
  .nextArrowCatProd {
    width: 40px !important;
    height: 40px !important;
  }

  .prevArrow > svg,
  .nextArrow > svg,
  .prevArrowCatProd > svg,
  .nextArrowCatProd > svg {
    font-size: 40px !important;
    flex-shrink: 0;
  }
}

@media (min-width: 1441px) {
  .defaultImage,
  .cardProducts > img {
    width: 100%;
    height: 18rem;
  }
}
