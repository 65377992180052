button.dropdownToggle {
  margin-left: 1rem;

  background: none !important;
  color: rgba(32, 33, 35, 0.5) !important;
  padding: 0.25rem 0.25rem !important;
  outline: 0 !important;
}

button.dropdownToggle:hover {
  background-color: #f6f5f5 !important;
}

button.dropdownToggle:focus {
  box-shadow: none;
}

button.dropdownToggle > svg {
  margin: 0 !important;
}
