.container {
  display: flex;
  flex-direction: column;
}

.container header {
  display: flex;
  justify-content: center;

  position: relative;

  padding: 1.5rem;
  background-color: #202123;

  border: 0;
}

.greenSquare {
  width: 2.5rem;
  height: 2.5rem;

  position: absolute;

  width: 41px;
  height: 41px;
  left: 48px;
  top: 45px;

  background-color: #81bcb7;
}

.blueSquare {
  width: 2rem;
  height: 2rem;

  position: absolute;
  right: 128px;
  top: 33px;

  background: #759aed;
}

.orangeSquare {
  width: 2rem;
  height: 2rem;

  position: absolute;
  left: 18px;
  top: 339px;

  background: #eb7129;
}

.checkout {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  padding: 3.5rem 6rem;
  background: linear-gradient(180deg, #202123 26%, #ffffff 26%);
}

.formContainer {
  flex: 0.64;

  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}

.formContainer form {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 1.5rem 1.5rem;

  margin-bottom: 1rem;
}

.formContainer form h3 {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 1.5;
  text-align: center;

  color: #202123;

  margin-bottom: 1.75rem;
}

.formContainer form span {
  color: #eb7129;
  font-size: 1.875rem;
}

.input {
  color: rgba(0, 0, 0, 0.49);
  border-radius: 0;
  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.25);
}

.input::placeholder {
  color: rgba(32, 33, 35, 0.5);
}

.unsubscribeMessage {
  margin-bottom: 2rem;
}

.unsubscribeMessage p {
  font-size: 0.75rem;
}

.unsubscribeMessage a,
.formContainer a {
  color: #eb7129;
  text-decoration: underline;
}

.informations {
  display: flex;
  flex-direction: column;

  flex: 1;
}

.planInformationSmall {
  color: #ffffff;
  text-align: center;
}

.planInformation h2,
.planInformationSmall h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  font-size: 3rem;

  color: #ffffff;

  margin-bottom: 2rem;
}

.planInformation p,
.planInformationSmall p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #ffffff;

  /* max-width: 48rem; */
}

.planInformation p + P,
.planInformationSmall p + P {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #eb7129;

  margin-bottom: 3.5rem !important;

  max-width: 48rem;
}
.planInformationSmall button,
.planInformation button,
.formContainer button {
  padding: 1rem 2rem;
  font-size: 16px;
  font-weight: 600;

  border-radius: 1px solid #000;

  background: #eb7129;
  color: #ffffff;
  border: 0;
  transition: background 180ms ease-in-out;
}

.planInformationSmall button:hover,
.planInformation button:hover,
.formContainer button:hover {
  background: #e56215;
}

.planInformationSmall button:disabled,
.planInformation button:disabled,
.formContainer button:disabled {
  cursor: not-allowed;
  filter: brightness(0.8);
}

.testimonialsSection {
  overflow: hidden;

  padding: 4rem;
  background: #eb7129;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

.testimonialsSection div:first-child > h2 {
  color: #ffffff;
}

.heading h2 {
  max-width: 566px;

  font-weight: 600;
  font-size: 3rem;
  line-height: 1.25;

  color: #ffffff;

  margin-bottom: 2rem;
}

.heading p {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
}

.cardCarousel {
  max-width: 603px;
  padding: 40px 30px 40px;
  background: #ffffff;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.cardCarousel > div:first-child > button {
  display: none !important;
}

.faqSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 3.5rem 6rem;
}

.faqSection h4 {
  color: #202123;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;

  margin-bottom: 3rem;
}

.faqList {
  width: 100%;
}

.container footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem;

  color: #ffffff;

  height: 7rem;
}

.container footer h4 {
  color: #ffffff;
  font-size: 1.5rem;
}

.bold {
  font-weight: 600;
}

.btnDisabled {
  width: 100%;
  border: 2px solid #eb7129 !important;
  background: transparent !important;
  color: #eb7129 !important;
}

@media (max-width: 768px) {
  .checkout {
    flex-direction: column;
    padding: 0 1.25rem 3rem 1.25rem;
  }

  .planInformationSmall {
    order: -1;

    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;

    margin-bottom: 0;
    padding-top: 2rem;
  }

  .formContainer {
    order: -1;
  }

  .formContainer a {
    align-self: center;
    margin: 1rem 0 0 0;
  }

  .faqSection {
    padding: 3rem 2rem;
  }

  .informations {
    order: -1;
  }

  .planInformation {
    display: none;
  }

  .container footer {
    font-size: 1rem;
  }

  .container footer img {
    max-width: 8rem;
  }

  .container footer h4 {
    font-size: 1rem;
  }

  .orangeSquare {
    display: none;
  }

  .cardCarousel {
    max-width: 100%;
  }

  .testimonialsSection {
    padding: 4rem 2rem;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .container footer h4 {
    font-size: 0.75rem;
  }

  .blueSquare,
  .greenSquare {
    display: none;
  }

  .planInformation {
    text-align: center;
    align-items: center;
    justify-content: center;

    margin-bottom: 1rem;
  }

  .heading h2 {
    font-size: 1.75rem;
  }

  .heading P {
    font-size: 1.125rem;
  }
}
