.img-curso-placeholder {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-container {
  width: 200px;
  height: 200px;
}

.background-container {
  width: 320px;
  height: 180px;
}
