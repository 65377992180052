.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: #909091;
}

.container li,
a {
  cursor: pointer;
}

.container li {
  font-size: 1rem;
  font-weight: 600;
}

.activePagination {
  color: #eb7129;
}
