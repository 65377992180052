.testimonialsSection {
  padding: 0.25rem 48px;
  overflow: hidden;
}

.testimonialsSection {
  padding: 0.3rem 1rem;
  background: #eb7129;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimonialsSection div:first-child > h2 {
  color: #ffffff;
}

.heading {
  width: 50%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cardCarousel {
  width: 603px;
  min-height: 100%;
  padding: 40px 30px 40px;
  background: #ffffff;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.cardCarousel > div:first-child > button {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .testimonialsSection {
    flex-direction: column;
    justify-content: center;
  }
  .cardCarousel {
    max-width: 100%;
    margin-top: 60px;
  }
}
