.defaultImageProduct {
  width: 256px;
  height: 370.83px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultImageProduct span {
  font-size: 72px;
  font-weight: 900;
  color: #ffffff;
}
