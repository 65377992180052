.badge {
  height: 36px;
  padding: 0 24px;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;

  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  font-weight: 600;
}
