.bannerSection {
  overflow: hidden;
}

.bannerItem {
  margin-top: 6.25rem;
  object-fit: cover;
  max-height: 88vh;
  width: 100%;
}

.bannerSection > div {
  width: 100%;
}

.prevArrow,
.nextArrow,
.prevArrowCatProd,
.nextArrowCatProd {
  text-align: center;
  border: none !important;
  background: transparent !important;
  cursor: pointer !important;
  margin: 3rem 0;
}

.prevArrow {
  left: 5px !important;
}

.nextArrow {
  right: 5px !important;
}

.prevArrowCatProd {
  left: -40px;
}

.nextArrowCatProd {
  right: -40px;
}

.bgImage {
  min-width: 100%;
  height: 300px;

  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 768px) {
  .prevArrow,
  .nextArrow,
  .prevArrowCatProd,
  .nextArrowCatProd {
    width: 40px !important;
    height: 40px !important;
  }

  .prevArrow > svg,
  .nextArrow > svg,
  .prevArrowCatProd > svg,
  .nextArrowCatProd > svg {
    font-size: 40px !important;
    flex-shrink: 0;
  }

  .bannerItem {
    max-height: 72vh;

    object-fit: cover;
  }
}
