.marginLeft {
  margin-left: calc(290px); 
}

.div_title {
  display: flex;
  grid-column: initial;
  margin-top: 20px;
  flex-wrap: nowrap;
}

.title_h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;

  color: #202123;
}

.paragraphUserBase {
  font-size: 18px;
  line-height: 1.4;
}

.paragraphUserBase b {
  color: #eb7129;
}

.div_search {
  display: flex;
  justify-content: flex-end;
}

.input_search {
  /* width: 272px;
  height: 20px; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin-right: 5px;
  border-radius: 0;
  width: 340px;
}

.cadastrar {
  /* width: 170px; */

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  justify-content: center;
}

.icon_search {
  width: 30px;
}

.btn_search {
  margin-right: 40px;
  justify-content: center;
}

.style_thead {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: rgba(32, 33, 35, 0.5);
}

.style_tbody {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: justify;

  color: #202123;
}

.td {
  vertical-align: middle !important;
}

.title_h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;

  color: #202123;
}

.email {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #202123;
  margin-bottom: 0;
}

.type_access {
  margin-top: 20px;
  margin-bottom: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #202123;
}
.input_email {
  background: #f6f5f5;
  border: 1px solid #aeadad;
  box-sizing: border-box;
}

.text_radio {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;

  color: #000000;
}

.title {
  font-weight: bolder;
}

.div_btn {
  margin-top: 20px;
  text-align: center;
}

.cancelar {
  background: #e5e5e5;
  border: 1px solid rgba(32, 33, 35, 0.5);
  box-sizing: border-box;
  color: #000000;
  margin-right: 20px;
  height: 45px;
}

.cancelar :hover {
  color: black;
}

.salvar {
  border: 1px solid rgba(32, 33, 35, 0.5);
  box-sizing: border-box;
  height: 45px;
}

.format_overflow {
  overflow-y: hidden;
}

.block {
  pointer-events: none;
  cursor: not-allowed !important;
}

.margin_spinner {
  margin-left: 5px;
}
