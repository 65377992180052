.questionsList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-top: 1rem;
}

.questionsList li {
  font-size: 1.125rem;
}
