.colorSelectorContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  justify-content: flex-end;
}

.colorSelectorContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 1rem;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.colorSelectorContent section {
  flex: 1;

  margin-right: 4rem;
}

.colorSelectorContent section:last-child {
  margin-right: 0;
}

.colorSelectorContent section > div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.875rem;
}

.colorSelectorContainer label {
  font-size: 1.25rem !important;

  font-style: normal;
  font-weight: 600;

  color: #202123;

  margin: 0;
}

.colorSelectorContainer p {
  font-size: 1rem;
  color: rgba(32, 33, 35, 0.5);

  margin-bottom: 0.75rem;
}

.colorSelectorContainer input {
  cursor: pointer;
}

.colorSelectorContainer button {
  min-width: 8.5rem;
  min-height: 1.5rem;
  font-size: 0.75rem !important;
}

.colorSelectorFooter {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.colorSelectorFooter button:first-child {
  margin-right: 1rem;
  color: #202123;

  background: #e5e5e5;
  border: 1px solid rgba(32, 33, 35, 0.5);
}
