.containerNoContent {
  width: 100%;
  margin: 6rem 0;

  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.content h3 {
  margin: 2rem 0 1rem;
}

.content p {
  width: 380px;
  margin: 0 auto;
}

.content img {
  max-width: 40%;
}

@media screen and (min-width: 768px) {
  .containerNoContent {
    margin: 6rem 0 0 0;
  }

  .content p {
    width: 500px;
    margin: 0 auto;
  }

  .content img {
    max-width: 25%;
  }
}
