.statusRefunded,
.statusPaid,
.statusCanceled {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 6.875rem;
  border-radius: 1.5rem;
  color: white;
}

.statusPaid {
  background: #22bb33;
}

.statusRefunded {
  background: #8977b6;
}

.statusCanceled {
  background: #bb2124;
}

.boletoLink {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .statusPaid,
  .statusCanceled {
    float: right;
    background: none;
    padding: 0;
    display: block;
    font-weight: bold;
  }

  .statusCanceled {
    color: #bb2124;
  }
  .statusPaid {
    color: #22bb33;
  }
}
