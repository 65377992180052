.dropzone-button {
  width: 100%;
  height: 100%;

  margin: 0 !important;
  padding: 0 !important;

  font-size: 12px;
  line-height: 1.4;
  font-weight: 600;
  background: #798bff;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 50%;
}
