.container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 1rem;
}

.container li,
.container p,
.container span {
  font-size: 1rem;
}

.continer ul li {
  line-height: 1.4;
}

.container li + li {
  margin: 0.5rem 0 0;
}

.container h3 {
  margin: 1.5rem 0;
  font-size: 1.25rem;
}

.container a {
  margin-left: 0.25rem;
}

.policyContainer {
  margin: 2rem auto 4rem;
  width: 100%;
  padding: 0 16rem;
}

.policyContentHead {
  margin-top: 4rem;
  text-align: center;
}

.policyContentHead img {
  height: 150px;
  max-width: 100%;
}

.policyHead p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.policyContentHead h3 {
  margin: 1rem 0 2rem;
  color: #202123 !important;
}

.content h3 {
  margin: 1rem 0 2rem;
  color: #202123;
}

.content h3,
.policyContentHead {
  font-size: 1rem;
}

.content {
  margin: 2rem 0 0 1rem;
  text-align: justify;
}

.content h3 {
  text-transform: uppercase;
}

.content ul > li,
.content ul > div span {
  font-size: 1rem;
  color: #202123;
  line-height: 1.5;
}

.content ul > span {
  margin-bottom: 1rem;
}

.content ul > li {
  margin-bottom: 1rem;
}

.content ul > li > ol {
  margin-left: 1rem;
  margin-top: 1rem;
}

.content ul > li > ol > li::marker {
  font-weight: bold;
}

.spacing {
  margin-left: 1rem;
}

.spacing > li > ol {
  list-style-type: lower-alpha;
  margin: 1rem 0 0 1rem;
}

.spacing > li {
  font-size: 1rem;
  color: #202123;
  line-height: 1.5;
}

.spacing > li > ol:last-child {
  margin-bottom: 1rem;
}

.content {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr));
}

.content ul > li:last-child {
  margin-bottom: 2rem;
}

.link {
  color: #eb7129;
  text-decoration: underline;
}

.goBack {
  margin: 1rem 0;
  font-size: 1rem;
  color: #1a73e8;
  text-decoration: underline;
}

.heading {
  font-size: 16px !important;
  font-weight: bold;
}

.table {
  width: 100%;
}

.tableLine {
  border: 1px solid #202123;
  text-align: center;
  line-height: 2rem !important;
}

.tableLineBody {
  border: 1px solid #202123;
  line-height: 2rem !important;
}

.tableLineBody span:first-child {
  border-right: 1px solid #202123;
}

.table tbody tr td {
  width: 300px;
  align-items: center;
}

@media (max-width: 560px) {
  .content {
    margin-left: 0;
  }

  .policyContentHead img {
    width: 50%;
  }
}
