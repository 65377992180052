.div {
  position: relative;
  font-size: 12px;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.01em;
  display: inline-block;
  padding: 0.3rem 0.8rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
