.accordionItem {
  display: flex;
  flex-direction: column;
}

.accordionButton {
  width: 100%;
  padding: 16px 30px;
  border: 0;
  background: #eb7129;
  border-radius: 10px;

  transition: background 180ms ease-in-out;

  display: flex;
  align-items: center;
}

.accordionButton {
  margin-top: 20px;
}

.accordionButton > span {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}

.accordionButton:focus {
  outline: none;
}

.accordionButton > span {
  margin-left: 20px;
}

.rotate {
  transform: rotate(90deg);
  transition: transform 180ms ease-in-out;
}

.accordionItemContainer {
  padding: 0px 30px;
  background: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  overflow: hidden;
  transition: max-height 180ms ease-in-out;
  transform: translateY(-10px);
}

.accordionContent {
  margin-bottom: 16px;
  padding: 16px 45px 24px;
  display: flex;
  flex-direction: column;
  color: #202123;
  font-size: 16px;
}

.accordionContent span,
.accordionContent a {
  color: #eb7129;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .accordionContent {
    padding: 45px 20px;
  }

  .accordionButton > span {
    font-size: 16px;
  }
}
