.pageBuilderContainer {
  display: flex;
  flex-direction: column;
}

.paragraphHome {
  font-size: 18px;
  line-height: 1.4;
}

.paragraphHome b {
  color: #eb7129;
}

.pageBuilderContainer > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 4rem;
}

.pageBuilderContainer h2 {
  font-size: 1.875rem;
}

.pageBuilderContainer button.reorderButton,
.pageBuilderContainer button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem 1rem;

  font-weight: 600;
  text-align: center;
  color: #f6f8fe;
  line-height: 1.5;
  letter-spacing: 0.02em;
  font-size: 1.125rem;

  vertical-align: middle;
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: #eb7129;

  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.pageBuilderContainer button:hover {
  filter: brightness(0.96);
}

.pageBuilderContainer button:disabled {
  cursor: not-allowed;
  filter: brightness(0.8);
}

.pageBuilderContainer button svg {
  margin-right: 0.5rem;
}

.pageBuilderContainer button.reorderButton {
  color: #eb7129;
  font-size: 1rem;
  background-color: transparent;

  border: 1px solid #eb7129;
}

.pageBuilderContainer button.reorderButton:hover {
  box-shadow: 0 0 0 1px currentColor;
}
