.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  width: 100%;

  grid-area: chart;

  border-radius: 0.5rem;
  border: 1px solid rgba(32, 33, 35, 0.25);
  padding: 1rem;
  background-color: #ffffff;
}

.divider {
  margin-top: 0.5rem;
  width: 100%;
  height: 1px;
  background-color: #20212340;
}

.container span {
  display: block;
}

.header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.title {
  color: #202123;
  font-size: 1.125rem;
  position: relative;
}

.summary {
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
}

.summary span {
  position: relative;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.summary span:first-child::before {
  content: '';
  position: absolute;
  top: 2px;
  left: -32px;
  width: 1rem;
  height: 1rem;
  background: #4bbe58;
  border-radius: 100%;
}

.summary span:last-child::before {
  content: '';
  position: absolute;
  top: 2px;
  left: -32px;
  width: 1rem;
  height: 1rem;
  background: #cc595b;
  border-radius: 100%;
}

.successRate {
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
}

.successRate {
  font-size: 0.875rem;
}

.successRate span:first-child {
  display: flex;
}

.successRate span:last-child {
  font-size: 2.25rem;
}
