.copyInput {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  width: 100%;

  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #20212326;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.copyInput:focus {
  border: 2px solid #eb7129;
}

.copyInput div:first-child {
  display: flex;
  align-items: center;
}

.copyInput p {
  font-size: 0.875rem;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
  margin: 0;
  background-color: transparent;
  color: #20212380;
  box-shadow: none;
  border: 0;
  transition: opacity 0.2s ease-in-out;
}

.copyButton:hover {
  opacity: 0.75;
}

.copyButton:active {
  border: 0;
  outline: 0;
}

.copyButton svg {
  flex-shrink: 0;
}
