.fw700 {
  font-weight: 700;
}

.container {
  width: 100%;
  height: 400px;
  background: url('../../../assets/images/bg/bg-blog.png') no-repeat;
  background-position: 75% 100%;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
}

.content h2 {
  margin: 0 0 24px 0;
  font-size: 40px;
  font-weight: 600;
}

.content h2 > b {
  color: #eb7129;
}

.content span {
  font-size: 16px;
  line-height: 1.6;
  color: #000000;
}

.content a {
  max-width: 150px;
  height: 44px;
  margin: 32px 0 0 0;

  font-weight: 500;
  font-size: 16px;

  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .container {
    background-position: top center;
    justify-content: flex-start;
  }

  .content {
    max-width: 1166px;
  }

  .content h2 {
    max-width: 650px;
    width: 100%;
    font-size: 48px;
  }

  .content span {
    max-width: 550px;
    width: 100%;
    font-size: 22px;
  }
}

@media screen and (min-width: 1400px) {
  .content {
    max-width: 1270px;
  }
}
