.fw700 {
  font-weight: 700;
}

.wrapperBlog {
  margin-top: 6.25rem;
}

.container {
  height: 244px;

  /* background: url('../../assets/images/bg/banner-blog.png') no-repeat; */
  background-position: right;
  background-size: cover;
}

.content {
  width: 100%;
  height: 100%;
  padding: 0 16px;
  margin: 0 auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content h2 {
  margin: 0 0 24px 0;
  font-size: 40px;
  font-weight: 600;
}

.signUpContainer {
  position: relative;
  width: 100%;
}

.signUpContainer h2 {
  color: #ffffff;
  font-size: 40px;
}

.signUpContainer span {
  margin: 24px 0;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;

  display: block;
}

.signUpContainer form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.signUpContainer form input {
  height: 44px;
  padding: 0 8px;
  background: #ffffff;
  border: 1px solid #aeadad;
}

.signUpContainer form input + input {
  margin: 16px 0;
}

.signUpContainer form input::placeholder {
  font-size: 16px;
  line-height: 1.4;
  color: #20212380;
}

.signUpContainer form button {
  height: 44px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  background: #ffffff;
  color: #eb7129;
  border: 1px solid #eb7129;
}

.squad1 {
  position: absolute;
  top: -1px;
  left: 0;
}

.squad2 {
  position: absolute;
  bottom: 24px;
  left: 40px;
}

.squad3 {
  position: absolute;
  bottom: 32px;
  left: 50%;
}

.squad4 {
  position: absolute;
  top: 48px;
  right: 38%;
}

.squad5 {
  position: absolute;
  top: 64px;
  right: -1px;
}

@media screen and (min-width: 768px) {
  .container {
    justify-content: flex-start;
  }

  .content {
    max-width: 1166px;
    width: 100%;
    margin: 0 auto;
  }

  .content h2 {
    max-width: 650px;
    width: 100%;
    font-size: 48px;
  }

  .content span,
  .signUpContainer span {
    max-width: 550px;
    width: 100%;
    font-size: 22px;
  }

  .signUpContainer {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .signUpContainer h2 {
    font-size: 48px;
  }

  .signUpContainer form {
    max-width: 350px;
    width: 100%;
  }

  .signUpContainer form button {
    width: 160px;
  }

  .itemsWrap {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, auto));
    gap: 32px;
  }
}

@media screen and (min-width: 1400px) {
  .content {
    max-width: 1270px;
  }

  .itemsWrap {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 390px));
    gap: 32px;
  }
}

.sectionContainer {
  position: relative;
  padding: 48px 0;
  min-height: 65vh;
  width: 100%;
  background: #ffffff;
}

.sectionContent {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .sectionContent {
    max-width: 1166px;
  }
}

@media screen and (min-width: 1400px) {
  .sectionContent {
    max-width: 1270px;
  }
}
