.container {
  display: flex;
  flex-direction: column;
}

.container .image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 15rem;
  max-height: 30rem;
}

.headerLogoButton {
  display: block;

  width: 100% !important;

  color: #666666 !important;
  background-color: #f6f5f5 !important;
  transition: all 0.2s ease-in-out;
  font-size: 0.875rem !important;
  padding: 1rem 2rem !important;
}

.headerLogoButton:hover {
  color: #666666 !important;
  filter: brightness(96%) !important;
}
