.img-card {
  border-radius: 30px 30px 0px 0px;
}

.title {
  font-size: 1.5rem;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-destaque .title {
  height: 55px;
}

.link {
  font-size: 18px;
}
