.aboutContainer {
  display: flex;

  margin-bottom: 1rem;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.aboutContainer form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.aboutContainer form > div:first-child {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;

  margin-bottom: 1rem;
}

.description {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.description textarea {
  flex: 1;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
  resize: none;
}

.description input {
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
  resize: none;
  margin-bottom: 1rem;
}

.description label {
  font-weight: 700;
  line-height: 1.1;
  color: #202123;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.aboutContainer h4 {
  font-size: 1.25rem;
  margin-bottom: 0.875rem;
}

.aboutContainer p {
  font-size: 1rem;
  color: rgba(32, 33, 35, 0.5);

  margin-bottom: 0.75rem;
}

.aboutImage {
  flex: 1;
}

.actions {
  margin: 1rem 0;

  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 1rem;
}

.actions button {
  min-width: 8.5rem;
  min-height: 1.5rem;
  font-size: 0.75rem !important;
}

.actions button.cancelButton {
  color: #202123;
  background: #e5e5e5;
}

.actions button.deleteButton {
  color: #bb2124;

  background: #e5e5e5;
  transition: all 0.2s ease-in-out;
}

.footer {
  margin-top: 1rem;
  padding: 0.5rem 0;
  border-top: 1px solid #20212380;
}

.footer div {
  display: flex;
  align-items: center !important;
}

.footer label {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;

  color: #202123;

  margin: 2px 0 0 0.5rem;
}
