.productsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  gap: 1rem;

  margin: 0 0 1rem 0;
}
.cardView > div > div button {
  background-color: #f6f5f5;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.containerHeader h4 {
  font-weight: 600;

  margin-bottom: 2rem;
  color: #202123;
}

.headerText {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #20212380;

  margin-bottom: 2rem;
}

.productsContainer form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.actions {
  margin: 1rem 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.actions div:last-child {
  display: flex;
  gap: 1rem;
}

.actions button {
  min-width: 8.5rem;
  min-height: 1.5rem;
  font-size: 0.75rem !important;
}

.actions button.cancelButton {
  color: #202123;
  background: #e5e5e5;
}

.actions button.deleteButton {
  color: #bb2124;

  background: #e5e5e5;
  transition: all 0.2s ease-in-out;
}

.footer {
  margin-top: 1rem;
  padding: 0.5rem 0;
  border-top: 1px solid #20212380;
}

.footer label {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;

  color: #202123;

  margin: 2px 0 0 0.5rem;
}
