.accordion {
  border: none;
}

.cardHeaderInstallments {
  height: 40px;
}

.accordionButton {
  height: 100%;
  width: 100%;
  border: 0;
  background: #2021231a;
  text-align: start;

  display: flex;
  align-items: center;
}

.accordionButton:focus {
  outline: none;
}

.accordionButton h2 {
  margin: 0 8px 0 0;
  font-size: 18px;
  font-weight: 600;
  color: #202123;
}

.cardBodyInstallments {
  padding: 20px;
  background: #2021231a;
}

.rotateIcon {
  transform: rotate(90deg);
  transition: transform 280ms ease-in-out;
}

@media screen and (max-width: 576px) {
  .accordionButton h2 {
    font-size: 14px;
  }
}
