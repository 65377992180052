.mediasContainer {
  display: flex;
  justify-content: center;

  margin-bottom: 1rem;
}

.headerText {
  margin-top: 2rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #20212380;
}

.mediasContainer form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mediasContainer form > div:first-child {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;

  margin-bottom: 1rem;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  position: relative;
}

.sectionHeader h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

.dragContainer {
  position: absolute;
  left: -24px;
  cursor: pointer;
}

.actionsHeaderContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actionsHeaderContainer p {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.actionsHeaderContainer select {
  border-radius: 0;

  height: auto;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  font-size: 0.75rem;
  background: #f6f5f5;
  border-radius: 0;
  border: 1px solid #aeadad;
  box-sizing: border-box;

  color: #0000007d;
}

.formSection {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.formSection input,
.formSection textarea {
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
  resize: none;
}

.formSection textarea {
  flex: 1;
  align-items: stretch;
}

.characterCounter {
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
  color: #0000007d;
  text-align: end;
}

.characterCounter p {
  display: inline;
}

.characterCounter .warning {
  color: #bb2124;
}

.formSection input {
  margin-bottom: 2.75rem;
}

.testimonialsImage label,
.formSection label {
  font-weight: 700;
  line-height: 1.1;
  color: #202123;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.mediasContainer h4 {
  font-size: 1.25rem;
  margin-bottom: 0.875rem;
}

.mediasContainer p {
  font-size: 1rem;
  color: rgba(32, 33, 35, 0.5);

  margin-bottom: 0.75rem;
}

.testimonialsImage {
  flex: 1;
}

.actions {
  margin: 1rem 0;

  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 1rem;
}

.actions button {
  min-width: 8.5rem;
  min-height: 1.5rem;
  font-size: 0.75rem !important;
}

.actions button.cancelButton {
  color: #202123;
  background: #e5e5e5;
}

.actions button.deleteButton {
  color: #bb2124;

  background: #e5e5e5;
  transition: all 0.2s ease-in-out;
}

.footer {
  margin-top: 1rem;
  padding: 0.5rem 0;
  border-top: 1px solid #20212380;
}

.footer div {
  display: flex;
  align-items: center !important;
}

.footer label {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;

  color: #202123;

  margin: 2px 0 0 0.5rem;
}
