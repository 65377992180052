.background-image-header {
  position: relative;
  margin-top: 5.375rem;
  background-image: url('../../assets/images/bg/banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-image-header:before {
  content: '';
  position: absolute;
  inset: 0px 0px 0px 0px;
  background: linear-gradient(#202123cc 50%, #f5f6f6 100%);
  box-shadow: 0 2px 2px 2px #f5f6f6;
  height: 100%;
  width: 100%;
}

.content {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin: calc(5.5rem + 5.375rem) 1rem;
  flex-direction: column;

  z-index: 10;
}

.content > .heading {
  font-size: 3rem;
  margin-bottom: 3rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #ffffff;
  z-index: 10;
}

.mt {
  margin-top: calc(6rem + 5.375rem); /* 96px ou 5.375rem = TAMANHO DA HEADER */
}

.categorias-destaque {
  padding: 10px;
  background: #eb7129;

  overflow: hidden;
}

.btn-block {
  width: 100% !important;
}

.text-black {
  color: #000;
}

.lead {
  font-size: 18px;
}

.btn-xl {
  padding: 0.625rem 2rem;
  font-size: 1.125rem !important;
  line-height: 2rem;
  border-radius: 5px;
}

.btn-destaque {
  background: white !important;
  color: #eb7129 !important;
  border: 1px #eb7129 !important;
  font-size: 18px !important;
}

.btn-destaque-outline {
  background: #eb7129 !important;
  border: 1px solid white !important;
  font-size: 18px !important;
}

.position {
  position: relative;
}

.position img {
  content: '';
  position: absolute;
}

@media (max-width: 720px) {
  .position img {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .content {
    width: 100%;
  }

  .content > .heading {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 1400px) {
  .position img {
    top: -185px;
  }
}

@media (min-width: 1400px) {
  .position img {
    top: -235px;
    left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .background-image-header {
    margin-top: 51px !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .heading {
    font-size: 1rem;
  }

  .background-image-header {
    margin-top: 51px !important;
  }

  .container-buttons .ml-3 {
    margin-left: 0px !important;
  }
  .flex-md-column-2 img {
    margin-top: 10px;
    text-align: center;
  }
  .justify-content-center-md {
    display: flex;
    justify-content: center !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .flex-md-column-2 .ml-5 {
    margin-left: 0px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .background-image-header {
    margin-top: 51px;
  }
}
