.containerSkeleton {
  /* height: 100%; */
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.imageSkeleton,
.titleSkeleton,
.usernameSkeleton,
.tagSkeleton {
  position: relative;
  overflow: hidden;
}

.imageSkeleton {
  width: 100%;
  background: #d9d9d9;

  width: 100%;
  height: 12rem;
}

.contentSkeleton {
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: initial;
}

.titleSkeleton {
  width: 100%;
  height: 1rem;
  background: #d9d9d9;
}

.imageSkeleton::after,
.titleSkeleton::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    #d9d9d9 0%,
    rgba(0, 0, 0, 0.05) 20%,
    #d9d9d9 40%,
    #d9d9d9 100%
  );
  background-size: 450px 400px;
  background-repeat: no-repeat;
  animation: shimmer 1s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
}
