.paragraphCoupon {
  font-size: 18px;
  line-height: 1.4;
}

.paragraphCoupon b {
  color: #eb7129;
}

.inputFormControl,
.inputCoupons {
  color: rgba(0, 0, 0, 0.49);
  background: #f6f5f5;
  border-radius: 0;
  border: 1px solid #aeadad;
}

.inputFormControl::placeholder,
.inputCoupons::placeholder {
  color: rgba(0, 0, 0, 0.49);
}

.inputPrepend {
  height: auto;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  background: #f6f5f5;
  color: rgba(0, 0, 0, 0.49);
  border-radius: 0;
  border-top: 1px solid #aeadad;
  border-right: 1px solid #aeadad;
  border-bottom: 1px solid #aeadad;
  border-left: none;
}

.inputPrepend:focus {
  outline: none;
}

.tableHead {
  background: #eb7129;
  color: #ffffff;
}

.tableHead tr {
  text-align: start;
}

.tableHead tr > th,
.tableContainer tbody tr > td {
  padding: 14px 0;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 14px;
}

.tableContainer tbody tr > td {
  line-height: 18px;
}

.tableContainer tbody > tr {
  border-bottom: 2px solid rgba(32, 33, 35, 0.25);
}

.tableContainer tbody tr td > span {
  padding: 4px 25px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 15px;
}

.fixedBottom {
  position: fixed;
  right: 289px;
  bottom: 20px;
  left: 0;
  transform: translateX(289px);
  z-index: 1030;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button {
  border: 0;
  background: none;
  color: #202123;
  font-weight: 600;
}

.pagination button.active {
  color: #eb7129;
}

.chevron {
  padding: 0;
  background: none;
  border: 0;
  border-radius: 10px;
}

.chevron:focus {
  outline: none;
}

.labelCoupons {
  color: #202123;
  font-weight: 600;
  font-size: 16px;
}

.warning,
.message {
  color: rgba(0, 0, 0, 0.49);
  line-height: 16px;
}

.modalBlur {
  backdrop-filter: blur(4px);
}

.cancelButton,
.saveButton {
  font-size: 16px;
  font-weight: 600;
}

.cancelButton {
  padding: 8px 18px;
  background: #e5e5e5;
  color: #202123;
  border: 1px solid #202123;
}

.cancelButton:hover {
  color: #202123;
}

.saveButton {
  padding: 8px 30px;
  background: #eb7129;
  color: #ffffff;
  border: 1px solid #eb7129;
  transition: background 180ms, border 180ms ease-in-out;
}

.saveButton:hover {
  background: #e06721;
  border: 1px solid #e06721;
}
