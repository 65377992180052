.container {
  display: flex;
  flex-direction: column;
}

.container form {
  display: flex;
  flex-direction: column;
}

.container textarea input[type='text'] {
  margin-top: 1rem;

  display: flex;
  width: 100%;

  font-size: 0.875rem;
  color: #aeadad;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;

  resize: none;

  font-family: monospace;
}

.container label {
  color: #20212380;
  font-size: 1.125rem;
  white-space: pre-wrap;
}

.container button {
  margin-top: 0.5rem;
}

.container button:disabled {
  pointer-events: none;
}

.formActions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  gap: 0.5rem;
}

.formActions div:last-child {
  display: flex;

  gap: 0.5rem;
}

.formActions button.cancelButton {
  color: #202123;
  background: #e5e5e5;
}

@media screen and (min-width: 1400px) {
  .imageWrapper {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .imageWrapper {
    overflow: visible;
    width: 100%;
  }

  .imageWrapper img {
    border-radius: 20px 20px 0 0;
    overflow: visible;
  }

  .container form {
    margin-top: 1rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .formWrapper form p {
    font-size: 0.875rem;
  }
}
