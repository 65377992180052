.aboutContainer {
  display: flex;
  gap: 4rem;
  margin-top: 8rem;

  padding: 4rem;
  overflow: hidden;
}

.imageWrapper {
  flex: 1;
}

.imageWrapper img {
  object-fit: cover;
  width: 100%;
}

.aboutContent {
  flex: 1;
  white-space: pre-wrap;
}

.aboutContent h2 {
  line-height: 1.2;
}

.aboutContent p {
  margin-top: 1rem;

  font-size: 1.2rem;
  line-height: 1.5;
}

@media only screen and (min-width: 1400px) {
  .aboutContent {
    flex: 1.5;
  }

  .aboutContent p {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .aboutContainer {
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }

  .aboutContent p {
    font-size: 1rem;
  }
}
