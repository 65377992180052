.container_geral {
  overflow-x: hidden;
}

.section__text {
  width: 100%;
}

.section {
  padding-bottom: 10px;
  border-bottom: 1px solid #8091a7 !important;
}

.container_anterior_proximo {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-left: 20px;
}

.container_name_course {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  padding-left: 25px;
  padding-right: 10px;
}

.arrow_icon {
  width: 30px;
  height: 30px;
  color: black;
}

.container_view_text {
  justify-content: center;
}

.div_view_content {
  display: flex;
  align-items: center;
  padding-bottom: 5rem;
}

.view_content {
  margin: 15px 15px 15px 15px;
  text-align: justify;
  width: 100%;
}

.div_concluido {
  margin: 15px;
}

.concluido {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}
