.defaultImage {
  background: #eb7129;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section__download {
  width: 100%;
}

.container_anterior_proximo {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-left: 20px;
}

.container_name_course {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  padding-left: 25px;
  padding-right: 10px;
}

.arrow_icon {
  width: 30px;
  height: 30px;
  color: black;
}

.container_view_text {
  justify-content: center;
}

.div_view_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.view_content {
  margin: 15px 15px 15px 15px;
  text-align: justify;
  width: 100%;
}

.div_concluido {
  margin-bottom: 20px;
}

.concluido {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  margin: 0;
}

.div_download {
  display: flex;
  justify-content: space-between;
  border: 2px solid #8091a7 !important;
  border-radius: 8px;
  width: 100%;
  margin-right: 20px;
}

.div_download_correction {
  display: flex;
  justify-content: space-between;
  border: 2px solid #8091a7 !important;
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
  margin-right: 20px;
}

.div_download a:hover {
  color: #eb7129;
  background: #f6f7f7;
}

.name_pdf {
  margin: 10px 10px 10px 10px;
  padding: 5px 5px 5px 5px;
  font-size: 15px;
}

.download {
  margin: 10px 10px 10px 10px;
  padding: 5px 5px 5px 5px;
  border: 2px solid #eb7129;
  border-radius: 5px;
  color: #eb7129;
}

.button_download {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  color: #eb7129;
  width: 18px;
  height: 18px;
}

.div_send_correction {
  display: flex;
  border: 2px solid #8091a7 !important;
  border-radius: 8px;
  width: 100%;
  margin-right: 20px;
  padding: 10;
}

.div_show_file_correction {
  display: flex;
  justify-items: space-between;
  align-content: right;
  border: 1px solid #8091a7 !important;
  width: 100%;
  margin-left: 10px;
  margin: 5px;
}

.div_correction_comment {
  display: flex;
  justify-content: space-between;
  border: 2px solid #8091a7 !important;
  border-radius: 8px;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
}
