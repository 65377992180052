footer.nk-footer {
  background: #202123 !important;
  border-top: 0;
}

.footer__subtitle {
  color: #ffffff;
  font-weight: 700 !important;
  margin-bottom: 1rem;
  font-size: 2rem;
  letter-spacing: -0.03em;
}

.footer__section {
  color: #ffffff;
  font-weight: bold !important;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  letter-spacing: -0.03em;
}

.footer__navigation > div > div > ul {
  margin-bottom: 2rem;
}

.footer__navigation > div > div > ul > li {
  font-family: 'Montserrat';
  font-weight: 500;
  margin-bottom: 1rem;
}

.footer__navigation > div > div > ul > li > a {
  color: #ffffff;
  font-size: 1rem;
  transition: 0.2s;
}

.footer__navigation > div > div > ul > li > a:hover {
  color: #eb7129 !important;
}

@media (max-width: 768px) {
  .footer__logo {
    margin-bottom: 2rem;
  }

  .footer__logo h2 {
    font-size: 1.5rem;
  }

  .footer__navigation {
    order: -1;
  }

  .footer__navigation ul:last-child {
    margin-bottom: 1rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .justify-content-start-md {
    justify-content: start !important;
  }
  .description-footer {
    margin-top: 20px;
    text-align: left !important;
  }
}
