.accordionQuestion {
  cursor: text;
}

.accordionQuestion,
.card {
  border-radius: 0px;
}

.accordionQuestion {
  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.5);
}

.cardHeader {
  height: 100% !important;
  padding: 0;
  background: #ffffff;

  display: flex;
}

.buttonCollapse {
  width: 100%;
  background: #ffffff;
  padding: 8px 12px;
  border: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonCollapse:active {
  outline: none;
}

.leftColumn,
.rightColumn {
  display: flex;
  align-items: center;
}

.iconContainer {
  margin-right: 8px;
  width: 14px;
  cursor: pointer;
}

.leftColumn span {
  color: #202123;
  font-size: 16px;
  font-weight: 600;
}

.rightColumn button {
  height: 26px;
  margin-right: 16px;
  background: #e5e5e5;
  font-size: 12px;
  font-weight: 600;
}

.deleteButton {
  min-width: 65px;
  color: #bb2124;
  border: 1px solid #bb2124;
}

.duplicateButton {
  min-width: 65px;
  color: #202123;
  border: 1px solid #20212380;
}

.rotateIcon {
  transform: rotate(90deg);
}

.questionText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 500px;
}
