.footerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-top: 1rem;
  flex-wrap: wrap;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.footerContainer p {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: justified;

  color: #202123;
  margin-top: 0;
  margin-bottom: 0;
}

.footerContainer form label {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0em;
  margin-bottom: 0.5rem;
}

.footerContainer form p {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;

  color: rgba(32, 33, 35, 0.5);

  margin-bottom: 1rem;
}

.footerContainer form input,
.footerContainer form textarea {
  margin-top: 0.5rem;

  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
  resize: none;
}

.inputGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.rowInputGroup {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.actions div:last-child {
  display: flex;
  gap: 1rem;
}

.actions button {
  min-width: 8.5rem;
  min-height: 1.5rem;
  font-size: 0.75rem !important;
}

.actions button.cancelButton {
  color: #202123;
  background: #e5e5e5;
}

@media (max-width: 768px) {
  .rowInputGroup {
    flex-direction: column;
    gap: 1rem;
  }
}
