.pageTitle {
  margin: 1rem 0 0 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  color: #202123;

  display: flex;
  align-items: center;
}

.container {
  margin: 1rem 0 0 0;
}

.inputLabel {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.1;
  color: #202123;
  font-size: 1rem;
}

.titleInput,
.subtitleInput,
.selectInput,
.textAreaInput {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  background: #f6f5f5;
  border: 1px solid #aeadad;
  border-radius: 0;
}

.textAreaInput {
  flex: 1 1;
  align-items: stretch;
}

.titleInput::placeholder,
.subtitleInput::placeholder .textAreaInput::placeholder,
.selectInput {
  color: #20212380;
}

.publishContainer {
  display: flex;
  align-items: flex-end;
}

.inputSchedule {
  width: auto;
}

.publishButton {
  min-height: 2.5rem;
  min-width: 6.5rem;

  background: #ea7129;
  color: #ffffff;

  font-weight: 600;

  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 180ms ease-in-out;
}

.publishButton:hover {
  filter: brightness(0.8);
}

.publishButton:disabled {
  filter: brightness(0.8);
  cursor: not-allowed;
}

@media only screen and (max-width: 576px) {
  .flexColumn {
    flex-direction: column;
  }

  .flexColumn div:first-child {
    margin-bottom: 1rem;
  }
}
