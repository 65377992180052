.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 4rem;
  text-align: center;
}

.noDataContainer img {
  height: 6rem;
  margin-bottom: 2rem;
  opacity: 0.56;
  pointer-events: none;
}

.noDataContainer h6 {
  font-weight: 600 !important;

  margin-bottom: 1rem;
}

.noDataContainer button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  text-align: center;
  color: #ffffff;
  line-height: 1.5;
  letter-spacing: 0.02em;
  font-size: 1rem;

  vertical-align: middle;
  border: 0px solid transparent;
  border-radius: 0px;
  background-color: #eb7129;

  transition: 0.2s;
  cursor: pointer;

  padding: 0.5rem 1rem;
}

.noDataContainer button:hover {
  filter: brightness(0.8);
}

.noDataContainer button:disabled {
  filter: grayscale();
  cursor: not-allowed;
}
