.dragDropContainer {
  height: auto;
  padding: 16px;
  border-radius: 6px;
  background: #f6f5f5;
  border: 1px solid #aeadad;
}

.dragDropContent {
  margin: 0 0 8px 0;
}

.buttonGroup {
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
}

.buttonGroup button {
  height: 32px;
  font-size: 14px;
  font-weight: 600;
}

.addButton {
  min-width: 162px;
  height: 32px;
  margin-right: 24px;
  font-weight: 600;
  background: #eb7129;
  color: #ffffff;
  border: 1px solid #eb7129;
}

.importButton {
  min-width: 168px;
  background: #e5e5e5;
  color: #202123;
  border: 1px solid rgba(32, 33, 35, 0.5);
}

.importButton:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.addButton:hover,
.importButton:hover {
  filter: brightness(95%);
  transition: filter 180ms ease-in-out;
}

.addButton:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.formContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.formContainer label {
  margin: 0 0 4px 0;
  font-size: 16px;
  font-weight: 600;
  color: #202123;
}

.formContainer input,
.formContainer button {
  padding: 7px 16px;
}

.formContainer button {
  min-width: 120px;
  margin: 8px 0 0 0;

  border: 0;
  background: #eb7129;
  color: #ffffff;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
}

.fileDownload {
  margin: 0 0 0 16px;
}

.fileDownload a {
  padding: 0 4px 0 0;
  color: #759aed;
  text-decoration: underline;
}

.previewContainer {
  display: inline;
}

.previewContainer span {
  font-size: 14px;
}

.previewContainer button {
  background: transparent;
  border: 0;
}

@keyframes hide {
  from {
    opacity: 1;
    display: block;
  }
  to {
    opacity: 0;
    display: none;
  }
}

.alert {
  animation: hide 2s 2s forwards;
}
