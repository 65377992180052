.brand {
  max-height: 1.9rem;
  max-width: 10rem;
  object-fit: contain;
}

.backgroundBanner {
  height: 2rem;
  width: 100vw;
  background-color: #eb7129;
  /* background: linear-gradient(
    177.67deg,
    rgba(255, 255, 255, 0) 89.86%,
    rgba(255, 255, 255, 0.368357) 93.2%,
    rgba(255, 255, 255, 0.670317) 94.66%,
    #ffffff 97.02%
  ); */
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

@media screen and (max-width: 441px) {
  .brand {
    height: 25px;
  }
}

.header-size {
  height: 3.45rem;
}

.dashboard-student {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.dashboard-student-header {
  display: flex;
  align-items: center;
  padding: 0px 42px;
  width: 100%;
  height: 92px;
  align-content: center;
  background-color: #202123;
  position: fixed;
  z-index: 9;
}

.dashboard-student-header__logo {
  width: 181px;
}

.dashboard-student-content-welcome {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  background: url('../../../assets/images/bg/bg-welcome-student.png') no-repeat center;
  background-size: cover;
}

.dashboard-student-content-welcome__title {
  color: #202123;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 417px;
}

.dashboard-student-content-nav {
  background: rgba(32, 33, 35, 0.1);
  display: flex;
  align-items: center;
}

.dashboard-student-content-nav-list {
  list-style: none;
}

.dashboard-student-content-nav-list__item {
  display: inline-block;
}

.dashboard-student-content-nav-list__link {
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #202123 !important;
  padding: 7px 15px;
  display: block;
  margin-right: 30px;
  border-bottom: solid 3px transparent;
}

.dashboard-student-content-nav-list__link--active {
  border-bottom: solid 3px #eb7129;
  color: #eb7129 !important;
}

.dashboard-student-content-routes {
  padding-top: 38px;
}

.dashboard-student-content {
  margin-top: 3.45rem;
}

@media (max-width: 768px) {
  .dashboard-student-content-welcome__title {
    font-size: 32px;
    line-height: 1.2;
  }

  .dashboard-student-content-nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-student-content-nav-list__item > a {
    font-size: 18px;
    margin: 8px 0;
    width: 180px;
    text-align: center;
  }
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .logo-link {
    margin: 0;
  }

  .logo-link img {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .header-size {
    height: 6.25rem;
  }

  .dashboard-student-content {
    margin-top: 6.25rem;
  }
}
