.inputPasswordWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;

  position: relative;
  flex-direction: column;
}

.inputPasswordWrapper i {
  position: absolute;
  top: 16%;
  right: 2.5%;
  color: rgba(32, 33, 35, 0.5);
  transition: 0.2s;
}

.inputPasswordWrapper svg {
  width: 1rem;
}

.inputPasswordWrapper i:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.input {
  color: rgba(0, 0, 0, 0.49);
  border-radius: 0;
  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.25);
}

.input::placeholder {
  color: rgba(32, 33, 35, 0.5);
}
