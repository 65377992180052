.container button span {
  font-size: 2.5rem !important;
  font-weight: 500;
}

.container > div:first-child {
  align-items: center;
}

.pixContainer {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 1rem;
}

.pixContainer > div:last-child {
  display: flex;
  gap: 1.5rem;
}

.QRCodeWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.QRCodeWrapper button {
  padding: 0.5rem;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.QRCodeWrapper button:hover {
  filter: brightness(0.9);
}

.QRCodeWrapper > svg {
  width: 100%;
}

.informationsWrapper {
  flex: 2;
}

.pixContainer ol {
  margin-top: 1rem;
  list-style: decimal;
}

.pixContainer ol li {
  margin-left: 1rem;
}

.observations {
  margin-top: 1.25rem;
}

.observations p,
.observations span {
  font-size: 0.875rem;
}

.observations div {
  display: flex;
  align-items: center;
}

.observations div > svg {
  color: #eb7129;

  font-size: 0.875rem;

  flex-shrink: 0;
}

.observations div span {
  margin-left: 4px;

  color: #eb7129;
}

.observations p {
  margin-left: 20px;

  color: #202123;
}

@media (max-width: 768px) {
  .pixContainer > div:last-child {
    flex-direction: column-reverse;
    gap: 1.5rem;
  }
}
