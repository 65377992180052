.without-access {
  margin: 1rem 0 1.75rem;
  line-height: 1.4;
  color: #202123;
}

@media screen and (max-width: 768px) {
  .without-access {
    font-weight: 500;
  }

  .warning-img {
    width: 100%;
    height: 280px;
  }

  .access-container {
    width: 100% !important;
  }
}
