.sectionContainer {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 48px 0;
}

.sectionContent {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .sectionContent {
    max-width: 1166px;
  }
}

@media screen and (min-width: 1400px) {
  .sectionContent {
    max-width: 1270px;
  }
}
