#rhap_current-time {
  margin-top: -60px;
  font-size: 14px;
}

.rhap_time {
  margin-top: -60px;
  font-size: 14px;
}

.rhap_progress-container {
  height: 0;
  margin: 0;
}

.rhap_progress-indicator {
  display: none;
  /* width: 6px;
  height: 6px;
  top: 0;
  background: white;
  margin-left: 0; */
}
.rhap_stacked .rhap_controls-section {
  margin-top: 30px;
}

.rhap_download-progress {
  height: 5px;
}

.rhap_progress-bar {
  margin-right: 8px;
}

.rhap_footer {
  margin-top: -35px;
  width: 30px;
}
