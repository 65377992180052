.cardContainer {
  margin: 0.5rem 1rem 0.5rem 0;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  list-style: none;
  transition: transform 180ms ease-in-out;
  z-index: 10;
  overflow: hidden;
}
