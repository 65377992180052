.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 1rem;
  text-align: center;
}

.noDataContainer p {
  font-size: 0.875rem !important;
}

.noDataContainer img {
  height: 6rem;
  margin-bottom: 2rem;
  opacity: 0.56;
  pointer-events: none;
}

.noDataContainer h6 {
  font-weight: 600 !important;

  margin-bottom: 1rem !important;
}

.noDataContainer a {
  color: #eb7129;
  font-weight: 600;
  transition: all 0.2s ease-out;
}

.noDataContainer a:hover {
  text-decoration: underline;
}
