.tableTHeader {
  color: #fff;
  background: #eb7129;
}

.tableTHeader tr,
.tableTHeader tr:first-child,
.tableTHeader th,
.tableTHeader th:last-child {
  border: 0;
}

.borderNone {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.tableTHeader tr th,
.tableTBody tr td {
  font-weight: 500;
  line-height: 32px;
  border: 0;
}

.tableTBody tr td {
  font-size: 16px;
  color: #202123;
}

@media screen and (max-width: 992px) {
  .tableTHeader tr th {
    line-height: 1.4 !important;
    vertical-align: middle;
  }
}
